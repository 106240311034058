<script setup lang="ts">
declare global {
  interface Window {
    HSStaticMethods: any
  }
}
import { onBeforeMount } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import { debounce } from 'lodash-es'
import authService from '@/services/auth.service'
import { initServiceRouter as subscribetionInitServiceRouter } from '@/services/subscription.service'
import { initServiceRouter as emailredirectionInitServiceRouter } from '@/services/emailredirection.service'
import routerService from '@/services/router.service'
import utilModelsService from './services/util.models.service'

const router = useRouter()

if (import.meta.env.SSR) {
  routerService.routerLogic(router)
}
routerService.pricingRouterLogic(router)

onBeforeMount(() => {
  authService.initServiceRouter(router)
  subscribetionInitServiceRouter(router)
  emailredirectionInitServiceRouter(router)
})
/**
 * callback function to call autoInit
 * @returns void
 */
const callback = debounce(() => {
  try {
    window.HSStaticMethods.autoInit()
  } catch (error) {
    return () => {}
  }
}, 100)
if (!import.meta.env.SSR) {
  utilModelsService.preventOverlayAccessibility()
  const mutationObserver = new MutationObserver(callback)
  mutationObserver.observe(document, { childList: true, subtree: true })
}
</script>

<template>
  <RouterView />
</template>

<style>
html.dark {
  color-scheme: dark;
}
</style>
