import recaptchaService from '@/services/recaptcha.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { FILE_MESSAGE_TYPE } from '@/utils/ide'

import axios from 'axios'
let controller: AbortController | null = null
export interface IPluginFileUploadRequest {
  projectKey: number
  content: any
  name: string
  clientId: string
}
export interface IFileUploadResponse {
  inputFiles: Array<string>
}
export interface IFileRemoveRequest {
  projectKey: number
  name: string
}
/**
 * Upload plugin file
 * @returns - The file upload response
 */
const uploadPluginFile = async (): Promise<Array<string>> => {
  const pluginFileUploadRequest: IPluginFileUploadRequest = {
    projectKey: 1001,
    content: usePluginStore().pluginFiles[0]?.content,
    name: usePluginStore().pluginFiles[0]?.name,
    clientId: usePluginStore().isClientkey || ''
  }
  return await new Promise((resolve, reject) => {
    useIdeStore().setUploadInProgress(true)
    axios
      .post('/api/projectSync/uploadInputFileWithText', pluginFileUploadRequest)
      .then((response: { data: IFileUploadResponse }) => {
        useIdeStore().setInputFiles(response.data.inputFiles)
        useIdeStore().setFileUploadMessages(
          FILE_MESSAGE_TYPE.SUCCESS,
          'File uploaded successfully.'
        )
        usePluginStore().setPluginIsFileUploaded(true)
        useIdeStore().setUploadInProgress(false)
        resolve(response.data.inputFiles)
      })
      .catch((error: { response: { status: number } }) => {
        if (error?.response?.status === 403) {
          useAuthStore().clearRobotCheck()
        }
        useIdeStore().setFileUploadMessages(FILE_MESSAGE_TYPE.ERROR, 'Unable to upload file.')
        useIdeStore().setUploadInProgress(false)
        reject(error)
      })
  })
}
/**
 *  Try upload file
 * @param event - The event
 */
const tryUploadFile = async (event: Event & { dataTransfer?: DataTransfer }) => {
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      const inputElement =
        event.type === 'change' ? (event.target as HTMLInputElement) : event.dataTransfer
      if (useIdeStore().isInputFiles.length >= useIdeStore().isMaxUploads) {
        useIdeStore().setFileUploadMessages(
          FILE_MESSAGE_TYPE.ERROR,
          'Maximum upload limit reached.'
        )
        return
      }
      if (inputElement?.files && inputElement.files[0].size >= useIdeStore().isMaxFileSize) {
        useIdeStore().setFileUploadMessages(
          FILE_MESSAGE_TYPE.ERROR,
          'Uploaded file has exceeded the maximum allowed file size. Max size: 1.049MB.'
        )
        return
      }

      if (!inputElement?.files || !inputElement.files[0]) return

      controller = new AbortController()
      const formData = new FormData()
      formData.append('projectKey', '1001')
      formData.append('file', inputElement?.files[0])

      useIdeStore().setUploadInProgress(true)

      await axios
        .post('/api/projectSync/uploadInputFile', formData, {
          signal: controller?.signal,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response: { data: IFileUploadResponse }) => {
          useIdeStore().setInputFiles(response.data.inputFiles)
          useIdeStore().setFileUploadMessages(
            FILE_MESSAGE_TYPE.SUCCESS,
            'File uploaded successfully.'
          )
          useIdeStore().setUploadInProgress(false)
        })
        .catch((error: { response: { status: number } }) => {
          if (error?.response?.status === 403) {
            useAuthStore().clearRobotCheck()
          }
          useIdeStore().setFileUploadMessages(FILE_MESSAGE_TYPE.ERROR, 'Unable to upload file.')
          useIdeStore().setUploadInProgress(false)
        })
    })
    .catch((error: any) => {
      useIdeStore().setFileUploadMessages(
        FILE_MESSAGE_TYPE.ERROR,
        error.message || 'Failed to verify captcha'
      )
    })
}
/**
 * Cancel upload
 */
const cancelUpload = () => {
  controller?.abort()
  controller = null
  useIdeStore().setFileUploadMessages(FILE_MESSAGE_TYPE.INFO, 'Upload Canceled.')
  useIdeStore().setUploadInProgress(false)
}
/**
 * Remove file
 * @param fileName - The file name
 */
const removeFile = async (fileName: string) => {
  const fileRemoveRequest: IFileRemoveRequest = {
    projectKey: 1001,
    name: fileName
  }
  await axios.post('/api/projectSync/deleteInputFile', fileRemoveRequest).then(() => {
    useIdeStore().removeInputFile(fileName)
    useIdeStore().setFileUploadMessages(
      FILE_MESSAGE_TYPE.SUCCESS,
      `File ${fileName} removed successfully.`
    )
  })
}

export default { tryUploadFile, cancelUpload, removeFile, uploadPluginFile }
