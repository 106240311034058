import type { IOnboardingSave } from '@/components/organisation/interface/IOnboardingSteps'
import { SUBSCRIPTION_PLAN_NAME } from '@/components/shared/subscription/enum/subscription.enum'
import { type IinitAuthResponse } from '@/services/auth.service'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const isAppInitiated = ref<boolean>(false)
  const kurukkuKuri = ref<string | null>(null)
  const isUserloggedIn = ref<boolean>(false)
  const isPremium = ref<boolean>(false)
  const robotChecked = ref<boolean>(false)
  const lastRobotCheckedTime = ref<number | null>(null)

  const notificationCount = ref<number | null>(null)

  // const username = ref<string | null>(null) // First Name
  const firstName = ref<string | null>(null)
  const lastName = ref<string | null>(null)
  const occupation = ref<string | null>(null)

  const userEmail = ref<string | null>(null)

  const googleClientId = ref<string>('')
  const environment = ref<string | null>(null)
  const profilePictureUrl = ref<string | null>(null)

  const redirectTo = ref<string | null>(null)
  const openModalOnLogin = ref<string>()
  const otpRedirectFrom = ref<string | null>('')
  const userEmailOtpVerified = ref<string | null>(null)
  const userEnteredOtp = ref<string | null>(null)
  const onboardingSteps = ref<IOnboardingSave>({
    firstName: '',
    lastName: '',
    occupation: '',
    onboarded: false,
    step: 0
  })

  const onboarderCompleted = ref<boolean>(false)
  const mobileDeviceLogin = ref<boolean>(false)
  const chromeExtensionLogin = ref<boolean>(false)
  const emailFromInvitationMail = ref<string | null>('')

  const onboarded = ref<boolean>(false)
  const currentOnboardingStep = ref<number | null>(null)

  const isInitiated = computed(() => {
    return isAppInitiated.value && kurukkuKuri.value !== null
  })

  const isLogedIn = computed(() => {
    return isUserloggedIn.value && robotChecked.value
    // && username.value !== null
  })

  const awaitRedirectTo = computed(() => {
    return redirectTo.value !== null
  })
  /**
   * Initializes the authentication response.
   * @param  initAuthResponse - The authentication response to initialize.
   */
  const initAuth = (initAuthResponse: IinitAuthResponse) => {
    setKurukkuKuri(initAuthResponse.kurukkuKuri as string)

    isUserloggedIn.value = initAuthResponse.isUserLoggedIn
    robotChecked.value = initAuthResponse.robotChecked
    if (initAuthResponse.profilePictureUrl) {
      profilePictureUrl.value = initAuthResponse.profilePictureUrl
    }
    if (initAuthResponse.isUserLoggedIn) {
      if (initAuthResponse.email)
        login(
          initAuthResponse.firstName,
          initAuthResponse.lastName,
          initAuthResponse.occupation,
          initAuthResponse.email,
          initAuthResponse.robotChecked
        )
      if (initAuthResponse.notificationCount)
        setNotificationCount(initAuthResponse.notificationCount)
    }
    if (initAuthResponse) setAppInitiated(true)
  }
  /**
   * Handles the disconnection event.
   */
  const onDisconnected = () => {
    setKurukkuKuri(null)
    setAppInitiated(false)
    logout()
  }
  /**
   * Sets the kurukku kuri.
   * @param value - The value to set.
   */
  const setKurukkuKuri = (value: string | null) => {
    kurukkuKuri.value = value
  }
  /**
   * Sets the app initiated.
   * @param value - The value to set.
   */
  const setAppInitiated = (value: boolean) => {
    isAppInitiated.value = value
  }

  /**
   *
   * @param fName - First Name of User
   * @param lName - Last Name of User
   * @param oName - Occupation Name of User
   * @param email - Email Address of user
   * @param robotCheckDone - robot check validation of User
   */
  const login = (
    fName?: string,
    lName?: string,
    oName?: string,
    email?: string,
    robotCheckDone?: boolean
  ) => {
    isUserloggedIn.value = true
    if (robotCheckDone) {
      robotChecked.value = true
      lastRobotCheckedTime.value = Date.now()
    }

    if (fName) firstName.value = fName
    if (lName) lastName.value = lName
    if (oName) occupation.value = oName
    if (email) userEmail.value = email
  }
  /**
   * Logs out the user.
   */
  const logout = () => {
    isUserloggedIn.value = false
    // username.value = null
    firstName.value = null
    lastName.value = null
    occupation.value = null
    userEmail.value = null
    robotChecked.value = false
    lastRobotCheckedTime.value = null
    notificationCount.value = null
  }

  /**
   * Sets the notification count.
   * @param count - The notification count to set.
   */
  const setNotificationCount = (count: number) => {
    notificationCount.value = count
  }

  /**
   * Sets the robot check done.
   */
  const robotCheckDone = () => {
    robotChecked.value = true
    lastRobotCheckedTime.value = Date.now()
  }
  /**
   * Clears the robot check.
   */
  const clearRobotCheck = () => {
    robotChecked.value = false
    lastRobotCheckedTime.value = null
  }

  /**
   * Set is premium according to platform plan (Team | Pro | Custom)
   * @param platformPlan - The platform plan to set.
   */
  const setIsPremium = (platformPlan: string | null) => {
    if (
      platformPlan &&
      (platformPlan == SUBSCRIPTION_PLAN_NAME.PRO ||
        platformPlan == SUBSCRIPTION_PLAN_NAME.TEACHER ||
        platformPlan == SUBSCRIPTION_PLAN_NAME.CUSTOM)
    )
      isPremium.value = true
  }

  /**
   * Sets google_client_id for  one tap login
   * @param  id - The notification count to set.
   */
  const setGoogleClientId = (id: string) => {
    googleClientId.value = id
  }
  /**
   * if not production set env
   * @param env env from joodle init
   */
  const setEnvironment = (env: string) => {
    environment.value = env
  }

  /**
   * Set user's profile picture URL
   * @param url url of the profile picture
   */
  const setprofilePictureUrl = (url: string | null) => {
    profilePictureUrl.value = url
  }

  /**
   * Redirects to the specified path.
   * @param path - The path to redirect to.
   */
  const setRedirectTo = (path: string | null) => {
    redirectTo.value = path
  }
  /**
   * Set OTP model path.
   * @param path - The path to set .
   */
  const setOtpRedirectFrom = (path: string) => {
    otpRedirectFrom.value = path
  }

  /**
   * @param values - setting the values for modal
   * @description - To open modal automatically when login
   */
  const setOpenModalOnLogin = (values: string) => {
    openModalOnLogin.value = values
  }

  /**
   * Set user email for OTP.
   * @param value - The email to set .
   */
  const setUserEmailForOTPVerified = (value: string) => {
    userEmailOtpVerified.value = value
  }

  /**
   * Set User Entered OTP.
   * @param value - The otp value to set .
   */
  const setUserEnteredOtp = (value: string) => {
    userEnteredOtp.value = value
  }
  /**
   * set onboarding steps values
   * @param value set onboarding steps latest values from api
   */
  const setOnboardingSteps = (value: IOnboardingSave) => {
    onboardingSteps.value.firstName = value.firstName
    onboardingSteps.value.lastName = value.lastName
    onboardingSteps.value.occupation = value.occupation
    onboardingSteps.value.step = value.step
  }
  /**
   * set onboarded completed flag
   * @param value set onboarding completed flag
   */
  const setOnboardedCompleted = (value: boolean) => {
    onboarderCompleted.value = value
  }

  /**
   * set mobile device flag value
   * @param value set mobile device value flag
   */
  const setMobileDeviceLogic = (value: boolean) => {
    mobileDeviceLogin.value = value
  }
  /**
   * set chrome device flag value
   * @param value set chrome device value flag
   */
  const setChromeExtensionLogin = (value: boolean) => {
    chromeExtensionLogin.value = value
  }
  /**
   * set the user email from email invitation query
   * @param value set user email value
   */
  const setEmailFromInvitationMail = (value: string) => {
    emailFromInvitationMail.value = value
  }

  /**
   * @param value onboarding complete bool
   */
  const setOnboarded = (value: boolean) => {
    onboarded.value = value
  }

  /**
   * @param value onboarding step value in number or null
   */
  const setCurrentOnboardingStep = (value: number | null) => {
    currentOnboardingStep.value = value
  }

  return {
    firstName,
    lastName,
    occupation,
    isInitiated,
    setAppInitiated,
    kurukkuKuri,
    setKurukkuKuri,
    isLogedIn,
    isPremium,
    setIsPremium,
    robotChecked,
    userEmail,
    robotCheckDone,
    clearRobotCheck,
    lastRobotCheckedTime,
    // username,
    isUserloggedIn,
    initAuth,
    onDisconnected,
    login,
    notificationCount,
    setNotificationCount,
    logout,
    googleClientId,
    setGoogleClientId,
    environment,
    setEnvironment,
    profilePictureUrl,
    setprofilePictureUrl,
    redirectTo,
    awaitRedirectTo,
    setRedirectTo,
    openModalOnLogin,
    setOpenModalOnLogin,
    otpRedirectFrom,
    setOtpRedirectFrom,
    userEmailOtpVerified,
    setUserEmailForOTPVerified,
    userEnteredOtp,
    setUserEnteredOtp,
    onboardingSteps,
    setOnboardingSteps,
    isAppInitiated,
    onboarderCompleted,
    setOnboardedCompleted,
    mobileDeviceLogin,
    setMobileDeviceLogic,
    chromeExtensionLogin,
    setChromeExtensionLogin,
    emailFromInvitationMail,
    setEmailFromInvitationMail,
    onboarded,
    setOnboarded,
    currentOnboardingStep,
    setCurrentOnboardingStep
  }
})
