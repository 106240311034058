import editorService from '@/services/ide/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import tabsService from '@/services/ide/tabs.service'
import { useIdeStore } from '@/stores/ide.store'
import { IDECONSTANT, TAB_ICONS_NAMES } from '@/utils/ide'
import { IDETABSVIEW } from '@/utils/tabs'

/**
 * Wait for the sync to finish
 * @param count - the count
 */
const multifileWaitForSync = async (count: number = 0) => {
  if (!projectTreeService.isSyncSuccess) {
    if (count > 8) {
      useIdeStore().setisCodeExecuting(false)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      multifileWaitForSync(count + 1)
    }
  }
}
/**
 * Execute the code
 * @param autoSave if true, auto save the project
 */
const execute = async (autoSave: boolean = true) => {
  // for advaced we dont need output script
  tabsService.updateTabVisiblity(TAB_ICONS_NAMES.BROWSER, IDETABSVIEW.IDE)
  await new Promise((resolve) => setTimeout(resolve, 1000))

  if (!useIdeStore().isAdvanced) {
    const script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
    const textArea = document.getElementById('outputTextArea') as HTMLTextAreaElement
    textArea.value = script
  }
  if (useIdeStore().isAdvanced) {
    projectTreeService.syncBeforeExecute()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    multifileWaitForSync()
  }

  const form = document.getElementById('outputForm') as HTMLFormElement
  form.setAttribute('target', 'output')
  form.submit()

  useIdeStore().setisCodeExecuting(false)
  if (autoSave) projectsService.autoSave()
}
/**
 * Try to execute the code
 * @param initiateExecutionBtn default initiateExecutionBtn will be true but when changing browser tab it can be false
 */
const tryExecute = (initiateExecutionBtn: boolean = true) => {
  if (useIdeStore().isCodeExecuting) return
  if (initiateExecutionBtn) {
    useIdeStore().setisCodeExecuting(true)
  }
  execute()
  if (useIdeStore().isAdvanced) {
    historyService.addToRecent()
    useIdeStore().setCurrentIframeSourceBrowser(useIdeStore()?.project?.home)
  } else {
    useIdeStore().setCurrentIframeSourceBrowser('/index.html')
    historyService.addToRecentHtml()
  }
}
/**
 * stop execute the code
 */
const stopExecution = () => {
  useIdeStore().setisCodeExecuting(false)
}

export default { tryExecute, execute, stopExecution }
