import axios, { HttpStatusCode } from 'axios'

/**
 * @description This function is used to save the onboarding details
 * @param requestObj Passing the onboarding details in request object
 * @returns The response object containing the onboarding details
 */
const onboardingSave = async (requestObj: any) => {
  return await axios
    .post('/api/register/onboarding/save', requestObj)
    .then(async (response) => {
      if (response.status === HttpStatusCode.Ok) {
        return response
      }
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description This function is used to get unique organization code
 * @param requestObj Passing organisationName in request object
 * @returns The response object containing the organization unique code
 */
const organizationUniqueCode = async (requestObj: any) => {
  return await axios
    .post('/api/guru-api/generateOrganisationCode', requestObj)
    .then(async (response) => {
      if (response.status === HttpStatusCode.Ok) {
        return response
      }
    })
    .catch((error) => {
      throw error
    })
}

export { onboardingSave, organizationUniqueCode }
