//Model
// Auth
export const AUTHMODELHS = {
  LOGIN: 'hs-login-modal',
  REGISTER: 'hs-register-modal',
  FORGOTPASSWORD: 'hs-forgot-password-modal',
  OTP: 'hs-otp',
  ONBOARDING: 'hs-onboarding',
  RESETPASSWORD: 'hs-resetpassword',
  OTPVERIFIEDSUCCESS: 'hs-otpverifiedsuccess',
  SWITCHINGACCOUNT: 'hs-switchingaccount',
  INVITEACCEPT: 'hs-inviteaccept'
}
export const PROFILEMODELHS = {
  CHANGEPASSWORD: 'hs-change-password-modal',
  CHANGEUSERNAME: 'hs-change-username-modal'
}

export const HOMEVIEWMODELS = {
  ONLINECOMPILER: 'hs-online-compiler-modal'
}
// ide
export const IDEVIEWMODELS = {
  SETTINGS: 'hs-settings-modal',
  SHARE: 'hs-share-modal',
  LIBRARIES: 'hs-libraries-modal',
  NEWPROJECT: 'hs-new-project-modal',
  NOTLOGGEDIN: 'hs-not-logged-in-modal',
  MYPROJECTS: 'hs-my-projects-modal',
  EXECUTEHISTORY: 'hs-execute-history-modal',
  AUTOSAVEENABLED: 'hs-auto-save-enabled-modal',
  SAVE: 'hs-save-modal',
  SAVEAS: 'hs-save-as-modal',
  EDITABLESHARE: 'hs-editable-share-modal',
  INSTANTSHARE: 'hs-instant-share-modal',
  COPY: 'hs-copy-modal',
  DOWNLOAD: 'hs-download-modal',
  OPENFROMFILE: 'hs-open-from-file-modal',
  IDESETTINGS: 'hs-ide-setting-modal',
  JDROIDGETMORECREDITS: 'hs-jdroid-get-more-credits-modal',
  STARTCODING: 'hs-start-coding-modal',
  MANAGECOLLABORATOR: 'hs-manage-collaborator-modal',
  ADDMANAGECOLLABORATOR: 'hs-add-manage-collaborator-modal'
}

export const DASHBOARD = {
  CREATE_PLUGIN: 'hs-create-plugin-modal',
  RENAME_PLUGIN: 'hs-rename-plugin-modal'
}

// feedback
export const FEEDBACKMODELHS = {
  FEEDBACK: 'hs-feedback-modal'
}

//guru
export const TEACHMODALHS = {
  REGISTER_INSTITUTE: 'hs-register-institute-modal',
  //Assignments
  CREATE_ASSIGNMENT: 'hs-create-assignment-modal',
  ACTIVATE_ASSIGNMENT: 'hs-activate-assignment',
  CREATE_QUESTION: 'hs-create-question-modal',
  CREATE_TESTCASE: 'hs-create-testcase',
  ADD_USER_STUDENT: 'hs-search-add-user-student',
  ADD_USER_STAFF_OR_TEACHER: 'hs-search-add-user-staff-or-teacher',
  IMPORT_STUDENT_GROUP: 'hs-import-student-group',

  //Student Group
  ADD_STUDENT_GROUP: 'hs-add-student-group',
  DELETE_STUDENT_GROUP_CONFIRMATION: 'hs-delete-student-group-confirmation',

  //Confirmation
  INVITE_CONFIRMATION: 'hs-invite-confirmation',
  ON_HOLD_CONFIRMATION: 'hs-on-hold-confirmation',
  DELETE_ASSIGNMENT_CONFIRMATION: 'hs-delete-assignment-confirmation',
  SUBMIT_ASSIGNMENT_CONFIRMATION: 'hs-submit-assignment-confirmation',
  EVALUATED_CONFIRMATION: 'hs-evaluated-confirmation',
  AUTO_EVALUATION_CONFIRMATION: 'hs-auto-evaluation-confirmation',
  REOPEN_EVALUATION_CONFIRMATION: 'hs-reopen-evaluation-confirmation',
  REOPEN_SUBMISSION_CONFIRMATION: 'hs-reopen-submission-confirmation',
  DISCLOSE_CONFIRMATION: 'hs-disclose-result-confirmation',
  CHANGE_ACTIVATION_CONFIRMATION: 'hs-change-activation-modal',
  REQUEST_REEVALUATION: 'hs-request-reevaluation-modal',

  //Errors
  ERROR_INSTRUCTION: 'hs-error-instruction',
  ERROR_NOT_READY_TO_EVALUATE: 'hs-not-ready-to-evaluate',
  //Delete/Remove Modals
  REMOVE_QUESTION_CONFIRMATION: 'hs-delete-question-modal',
  REMOVE_USER_STUDENT_CONFIRMATION: 'hs-remove-student-modal',
  REMOVE_USER_STAFF_OR_TEACHER_CONFIRMATION: 'hs-remove-teacher-modal',
  REMOVE_USER_CONFIRMATION: 'hs-remove-user-modal',
  EDIT_USER: 'hs-edit-user-modal',
  //Shared
  ADD_USER: 'hs-add-user-modal'
}

export const ASSESSMENT_MODAL_HS = {
  //Assessments
  CREATE_ASSESSMENT: 'hs-create-assessment-modal',
  DELETE_ASSESSMENT: 'hs-delete-assessment-modal',
  ADD_TEST_CASE: 'hs-add-test-case',
  ERROR_MESSAGE: 'hs-error-message-modal',
  PUBLISH_ASSESSMENT: 'hs-publish-assessment-modal',
  ON_HOLD_CONFIRMATION: 'hs-on-hold-confirmation-modal',
  REMOVE_QUESTION: 'hs-remove-question-modal',

  //Users
  ADD_STUDENT: 'hs-add-student-modal',
  ADD_TEACHER: 'hs-add-teacher-modal',
  REMOVE_STUDENT: 'hs-remove-student-modal',
  REMOVE_TEACHER: 'hs-remove-teacher-modal',

  //Switch
  SWITCH_TO_ORG: 'hs-switch-to-org-modal'
}

export const COURSE_MODAL_HS = {
  //COURSEs
  CREATE_COURSE: 'hs-create-course-modal',
  DELETE_COURSE: 'hs-delete-course-modal',
  PUBLISH_COURSE: 'hs-publish-course-modal',
  // ON_HOLD_CONFIRMATION: 'hs-on-hold-confirmation-modal',
  REMOVE_SECTION: 'hs-remove-course-section-modal',
  REMOVE_QUIZ_OR_LESSON: 'hs-remove-quiz-or-lesson-modal',
  REMOVE_QUESTION: 'hs-quiz-delete-modal'

  //Users
  // ADD_STUDENT: 'hs-add-student-modal',
  // ADD_TEACHER: 'hs-add-teacher-modal',
  // REMOVE_STUDENT: 'hs-remove-student-modal',
  // REMOVE_TEACHER: 'hs-remove-teacher-modal',

  //Switch
  // SWITCH_TO_ORG: 'hs-switch-to-org-modal'
}

export const TESTCASE_MODAL_HS = {
  CREATE_TESTCASE: 'hs-create-testcase-modal'
}

//Subscriptions
export const SUBSCRIPTION_MODAL = {
  SUBSCRIBE: 'hs-subscribe',
  CANCEL: 'hs-cancel-subscribe'
}

export const USER_MODAL_HS = {
  DELETE_USER: 'hs-delete-user-modal',
  CHANGE_ACTIVATION: 'hs-change-activation-modal'
}

export const TEAMS_MODAL_HS = {
  DELETE_STUDENT_GROUP: 'hs-delete-student-group-modal'
}

//Upload Profile Photo
export const UPLOADPHOTOMODAL = {
  UPLOAD: 'hs-upload-photo-modal'
}
// API Plan
export const API_PLAN = {
  VIDEO: 'hs-video-modal'
}

// Platform Plan Switch
export const PLATFORM_PLAN = {
  SWITCH: 'hs-switch-account-modal',
  CUSTOM: 'hs-custom-alert-modal'
}

// Confirm
export const CONFIRM = {
  CONFIRM: 'hs-confirm'
}

export const EXERCISE_MODAL_HS = {
  CREATE_EXERCISE: 'hs-create-exercise-modal',
  DELETE_EXERCISE: 'hs-delete-exercise-modal',
  PUBLISH_EXERCISE: 'hs-publish-exercise-modal',
  REMOVE_TESTCASE: 'hs-remove-testcase-modal'
}

//Bulk upload modal
export const MANAGE_TEAMS = {
  CREATE_BULK_UPLOAD: 'hs-create-bulk-upload',
  ADD_USERS: 'hs-add-users'
}

export const PROFILE_MODAL = {
  UPDATE_EMAIL_ADDRESS: 'hs-update-email-address'
}
