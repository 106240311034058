/* eslint-disable jsdoc/require-jsdoc */
import { META, setDefaultPath } from '@/utils/meta'

import contentAppRoutes from '@/router/contentAppRoutes'
import embedRoutes from '@/router/embedRoutes'
import ideRoutes from '@/router/IdeRoutes'
import jdroidRoutes from '@/router/jdroidRoutes'
import organisationRoutes from '@/router/organisationRoutes'
import { type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/login-to-continue',
    name: 'login-to-continue',
    component: () => import('@/views/LoginToContinue.vue')
  },
  {
    path: '/signup-to-continue',
    name: 'signup-to-continue',
    component: () => import('@/views/SignUpToContinue.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: META.home
  },
  {
    path: '/discord',
    name: 'discord',
    component: () => import('@/views/DiscordView.vue'),
    meta: META.discord
  },
  {
    path: '/faq',
    redirect: '/docs'
  },
  {
    path: '/code-online-compiler-ide',
    name: 'code-online-compiler-ide',
    redirect: () => {
      return { path: '/start-coding' }
    },
    meta: META.code
  },
  {
    path: '/integrate-online-ide-compiler-api-plugins',
    name: 'compilerApi',
    component: () => import('@/views/IntegrateView.vue'),
    meta: META.integrate
  },
  {
    path: '/intergrate-online-ide-compiler-api-plugins',
    redirect: () => {
      return { path: '/integrate-online-ide-compiler-api-plugins' }
    },
    meta: META.integrate
  },
  {
    path: '/online-assessment-teaching',
    name: 'online-assessment-teaching',
    component: () => import('@/views/GuruView.vue'),
    meta: META.teachassess,
    children: [
      {
        path: '',
        name: 'guruHome',
        component: () => import('@/components/guru/GuruHome.vue')
      }
    ]
  },
  {
    path: '/subscribe-api',
    name: 'subscribe-api',
    component: () => import('@/views/APIPlanUpdate.vue'),
    meta: META.api,
    children: [
      {
        path: '',
        name: 'subscribe-api-plan',
        component: () => import('@/components/api-plan/dashboard/APIDashboard.vue')
      },
      {
        path: ':type/:status',
        name: 'subscribe-api-result',
        component: () => import('@/components/api-plan/subscribe/APISubscribeResult.vue')
      }
    ]
  },
  {
    path: '/pay/:type/:plan',
    name: 'pay',
    component: () => import('@/views/APIPayment.vue'),
    meta: META.api
  },
  {
    path: '/plans-payments',
    name: 'plans-payments',
    component: () => import('@/views/PlansPayments.vue'),
    meta: META.api
  },
  ...ideRoutes,
  ...contentAppRoutes,
  ...organisationRoutes,
  ...embedRoutes,
  ...jdroidRoutes,
  {
    path: '/start-coding',
    name: 'start-coding',
    component: () => import('@/views/DefaultIDE.vue'),
    meta: Object.assign({}, META.code, {
      isDefaultIdeView: true
    })
  },
  {
    path: '/a/:shareId',
    name: 'share',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId,
      byPost: route.query.byPost
    }),
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: false
    })
  },
  {
    path: '/o/:collabId',
    name: 'collaborator',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      collabId: route.params.collabId
    }),
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: false
    })
  },
  {
    path: '/ia/:shareId',
    name: 'ishare',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId,
      byPost: route.query.byPost
    }),
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: true
    })
  },
  // {
  //   path: '/configure-plugin/:pluginId',
  //   name: 'dashboard-configure-plugin',
  //   component: () => import('@/views/dashboard/plugin/ConfigurePlugin.vue'),
  //   props: (route: RouteLocationNormalized) => ({
  //     pluginId: route.params.pluginId
  //   })
  // },
  {
    path: '/profile',
    component: () => import('@/views/dashboard/settings/MyProfileView.vue'),
    meta: META.profile,
    children: [
      {
        path: '',
        name: 'user-profile',
        component: () => import('@/views/dashboard/settings/MyProfileHomePage.vue')
      }
    ]
  },
  {
    path: '/profile/organisations',
    component: () => import('@/views/dashboard/settings/MyProfileView.vue'),
    meta: META.default
  },
  {
    path: '/html-css-javascript-online-editor',
    name: 'html',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId
    }),
    meta: Object.assign({}, META.html, {
      isShared: true
    })
  },
  {
    path: '/h/:shareId',
    name: 'htmlShare',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId
    }),
    meta: Object.assign({}, META.html, {
      isShared: true,
      isInstantShare: false
    })
  },
  {
    path: '/ih/:shareId',
    name: 'ihtmlShare',
    component: () => import('@/views/IDE.vue'),
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId
    }),
    meta: Object.assign({}, META.html, {
      isShared: true,
      isInstantShare: true
    })
  },
  {
    path: '/online-mysql-terminal',
    name: 'mysqlTerminal',
    component: () => import('@/views/TerminalView.vue'),
    meta: META.mysql,
    beforeEnter: [setDefaultPath]
  },
  {
    path: '/online-mongodb-terminal',
    name: 'mongoTerminal',
    component: () => import('@/views/TerminalView.vue'),
    meta: META.mongo,
    beforeEnter: [setDefaultPath]
  },

  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/TermsView.vue'),
    meta: META.default
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyView.vue'),
    meta: META.default
  },
  {
    path: '/pricing',
    name: 'pricing',
    meta: META.pricing,
    children: [
      {
        path: '',
        name: 'pricing-main',
        component: () => import('@/views/PricingView.vue'),
        props: () => ({
          productPage: false
        })
      },
      {
        path: ':tab',
        name: 'pricing-tab',
        component: () => import('@/views/PricingView.vue'),
        props: () => ({
          productPage: true
        })
      }
    ]
  },
  {
    path: '/payment/:type/:plan',
    name: 'payment',
    component: () => import('@/views/PaymentView.vue'),
    meta: META.default
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/AboutView.vue'),
    meta: META.aboutus
  },
  {
    path: '/compiler-ide-plugin/',
    name: 'compilerIdePlugin',
    redirect: () => {
      return { path: '/integrate-online-ide-compiler-api-plugins' }
    },
    meta: META.api
  },

  {
    path: '/register/verifyRegistration',
    name: 'verifyRegistration',
    component: () => import('@/views/VerifyRegistration.vue'),
    // eslint-disable-next-line
    props: (route: RouteLocationNormalized) => ({ token: route.query.t }),
    meta: META.default
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminView.vue'),
    meta: META.default
  },
  {
    path: '/solutions/education-institutions',
    name: 'educationSolution',
    component: () => import('@/views/SolutionEducationView.vue'),
    meta: META.educators
  },
  {
    path: '/solutions/education-technology',
    name: 'eduTechSolution',
    component: () => import('@/views/SolutionEduTechView.vue'),
    meta: META.edutech
  },
  {
    path: '/solutions/software-library-demostration',
    name: 'libProviderSolution',
    component: () => import('@/views/SolutionLibProviderView.vue'),
    meta: META.libprovider
  },
  {
    path: '/case-study/kansas',
    name: 'casestudyKansas',
    component: () => import('@/views/casestudy/CaseStudyKansas.vue'),
    meta: META.caseStudyKansas
  },
  {
    path: '/case-study/itext',
    name: 'casestudyiText',
    component: () => import('@/views/casestudy/CaseStudyiText.vue'),
    meta: META.caseStudyItext
  },
  {
    path: '/case-study/kodezi',
    name: 'caseStudyKodezi',
    component: () => import('@/views/casestudy/CaseStudyKodezi.vue'),
    meta: META.caseStudyKodezi
  },
  {
    path: '/case-study/linkgroup',
    name: 'caseStudyLinkGroup',
    component: () => import('@/views/casestudy/CaseStudyLinkGroup.vue'),
    meta: META.caseStudyLinkgroup
  },
  {
    path: '/case-study/outlier',
    name: 'caseStudyOutlier',
    component: () => import('@/views/casestudy/CaseStudyOutlier.vue'),
    meta: META.caseStudyOutlier
  },

  {
    path: '/contact-us',
    name: 'contactus',
    component: () => import('@/views/ContactUsView.vue'),
    meta: META.contactus
  }
]
export const allRoute = [
  {
    path: '/',
    component: () => import('@/layouts/AppLayout.vue'),
    children: routes
  },
  {
    path: '/langs-and-versions-table/',
    name: 'langsAndVersionsTable',
    component: () => import('@/views/LangAndVersionsTable.vue')
  },
  {
    path: '/embed',
    name: 'embed',
    component: () => import('@/layouts/GuestAppLayout.vue'),
    children: [
      {
        path: '/embed/v0/:shareId',
        name: 'embedIDE',
        component: () => import('@/views/EmbedIDE.vue'),
        // eslint-disable-next-line
        props: (route: RouteLocationNormalized) => ({
          shareId: route.params.shareId,
          set_args: route.query.set_args
        }),
        meta: { isInstantShare: false }
      },
      {
        path: '/embed/v1/:customkey',
        name: 'customEmbeds',
        component: () => import('@/views/embeds/EmbedsIDE.vue'),
        props: (route: RouteLocationNormalized) => ({
          customkey: route.params.customkey
        })
      }
    ]
  },
  {
    path: '/iembed',
    name: 'iembed',
    component: () => import('@/layouts/GuestAppLayout.vue'),
    children: [
      {
        path: '/iembed/v0/:shareId',
        name: 'iembedIde',
        component: () => import('@/views/EmbedIDE.vue'),
        // eslint-disable-next-line
        props: (route: RouteLocationNormalized) => ({
          shareId: route.params.shareId,
          set_args: route.query.set_args
        }),
        meta: { isInstantShare: true }
      }
    ]
  },
  {
    path: '/plugin',
    name: 'ideplugin',
    component: () => import('@/layouts/GuestAppLayout.vue'),
    children: [
      {
        path: '/plugin',
        name: 'plugin',
        component: () => import('@/views/PluginIDE.vue')
      },
      {
        path: '/plugin/v0/:clientkey/:customkey',
        name: 'customPlugin',
        component: () => import('@/views/CustomPluginIDE.vue'),
        props: (route: RouteLocationNormalized) => ({
          clientkey: route.params.clientkey,
          customkey: route.params.customkey
        })
      }
    ]
  },
  {
    path: '/mobile-app/',
    name: 'mobile',
    meta: META.default,
    // eslint-disable-next-line
    component: () => import('@/views/mobile/MobileApp.vue'),
    children: [
      {
        path: '/mobile-app/login/:token',
        name: 'mobileLogin',
        component: () => import('@/views/mobile/MobileLogin.vue')
      },
      {
        path: '/mobile-app/login-success/:token',
        name: 'mobileLoginSuccess',
        component: () => import('@/views/mobile/MobileLoginSuccess.vue')
      },
      {
        path: '/mobile-app/links/post-login/',
        name: 'mobilePostLogin',
        component: () => import('@/views/mobile/MobileApp.vue')
      }
    ]
  },
  {
    path: '/extension/',
    name: 'extension',
    meta: META.default,
    component: () => import('@/views/extension/ChromeExtension.vue'),
    children: [
      {
        path: 'login',
        name: 'extensionLogin',
        component: () => import('@/views/extension/ExtensionLogin.vue')
      },
      {
        path: 'login-success',
        name: 'extensionLoginSuccess',
        component: () => import('@/views/extension/ExtensionLoginSuccess.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/NotFound.vue'),
        meta: META.default
      }
    ]
  }
]
