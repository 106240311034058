import { LIBRARY, type ILibrary } from '@/utils/libsMeta'
import { getLanguageCount } from '@/utils/sharedData/languages'

import { type RouteMeta } from 'vue-router'
export interface IMeta extends RouteMeta {
  title?: string
  advanceTitle?: string
  metaTags?: { name: string; content: string }[]
  header?: string
  fileName?: string
  canonicalPath?: string
  terminal?: string
  language?: string
  aceCode?: string
  defaultVersion?: number
  versions?: string[]
  langDisplayName?: string
  isCompile?: boolean
  isPlus?: boolean
  plusVersions?: string[]
  library?: ILibrary
  editableTypes?: string[]
  sampleScript?: string
  blocklyAceCodes?: string[]
  blocklyLanguages?: string[]
  blocklyLanguageVersions?: number[]
  isAdvanced?: boolean
  basicPath?: string
}
export enum MetaTagName {
  Description = 'description',
  AdvanceDescription = 'advanceDescription',
  Keywords = 'keywords'
}

export const LANGUAGEMETA: { [key: string]: IMeta } = {
  java: {
    metaTags: [
      {
        name: 'description',
        content: "Write and execute Java code online using JDoodle's Free Java online compiler."
      },
      {
        name: 'advanceDescription',
        content:
          "Write Java code and run it online with JDoodle's Java online compiler. Java Advanced IDE with multifile support."
      },
      {
        name: 'keywords',
        content:
          'Online Java Compiler - Online Java Editor - Online Java IDE - Java Coding Online - Online Java Runner -  Share Save Java online'
      }
    ],
    title: 'Online Java Compiler | Java Editor',
    advanceTitle: 'Online Java Compiler | Advanced IDE',
    header: 'Java Code',
    fileName: '.java',
    canonicalPath: 'online-java-compiler',
    language: 'java',
    versions: ['JDK 1.8.0_66', 'JDK 9.0.1', 'JDK 10.0.1', 'JDK 11.0.4', 'JDK 17.0.1', 'JDK 21.0.0'],
    langDisplayName: 'Java',
    isCompile: false,
    isPlus: true,
    library: LIBRARY.maven,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.java'],
    sampleScript: `/*
Welcome to JDoodle!

You can execute code here in 88 languages. Right now you’re in the Java IDE.

  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.

  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->

  3.Try the menu buttons on the left. Save your file, share code with friends and open saved projects.

Want to change languages? Try the search bar up the top.
*/

public class MyClass {
  public static void main(String args[]) {
    int x=10;
    int y=25;
    int z=x+y;

    System.out.println("Sum of x+y = " + z);
  }
}`
  },
  c: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write C code and run it online with JDoodle's C online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write C code and run it online with JDoodle's C online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Online C Compiler - Online C Editor - Online C IDE - C Coding Online - Online C Runner -  Share Save C online'
      }
    ],
    title: 'Online C Compiler',
    advanceTitle: 'Online C Code Compiler | C Language Advanced IDE',
    header: 'C Code',
    fileName: 'c',
    canonicalPath: 'c-online-compiler',
    language: 'c',
    aceCode: 'c_cpp',
    versions: [
      'GCC 5.3.0',
      'Zapcc 5.0.0',
      'GCC 7.2.0',
      'GCC 8.1.0',
      'GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    langDisplayName: 'C',
    isCompile: true,
    isPlus: true,
    plusVersions: ['GCC 13.2.1'],
    library: LIBRARY.Arch,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.c', '.h'],
    sampleScript: `/* 
Welcome to JDoodle!

You can execute code here in 88 languages. Right now you’re in the C IDE.

  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.

  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->

  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.

Want to change languages? Try the search bar up the top. 
*/
#include<stdio.h>

int main() {
    int x=10;
    int y=25;
    int z=x+y;
    printf("Sum of x+y = %i", z);
}`
  },
  c99: {
    metaTags: [
      {
        name: 'description',
        content:
          'The best online C99 compiler and editor allows you to write, compile, and execute C99 code directly from your browser. Try JDoodle now!'
      },
      {
        name: 'advanceDescription',
        content:
          "Online C99 Compiler compiler and editor allows you to write C99 Code. Compile and Execute on JDoodle's Online C99 Advanced IDE."
      },
      {
        name: 'keywords',
        content:
          'Online C99 Compiler - Online C99 Editor - Online C99 IDE - C99 Coding Online - Online C99 Runner -  Share Save C99 online'
      }
    ],
    title: 'Online C99 Compiler',
    advanceTitle: 'Online C99 Compiler',
    header: 'C99 Code',
    fileName: 'c',
    canonicalPath: 'compile-c99-online',
    language: 'c99',
    aceCode: 'c_cpp',
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    langDisplayName: 'C-99',
    isCompile: true,
    isPlus: true,
    plusVersions: ['GCC 13.2.1'],
    library: LIBRARY.pacman99,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.c', '.h'],
    sampleScript: `#include<stdio.h>

int main(void) {
    int x=10;
    int y=25;
    int z=x+y;
    printf("Sum of x+y = %i", z);
}`
  },
  cpp: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write C++ code and run it online with JDoodle's C++ online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Compile, run & debug C++ programs online with JDoodle's C++ Online Compiler. Advanced IDE with multifile support."
      },
      {
        name: 'keywords',
        content:
          'Online C++ Compiler - Online C++ Editor - Online C++ IDE - C++ Coding Online - Online C++ Runner -  Share Save C++ online'
      }
    ],
    title: 'C++ Online Compiler',
    advanceTitle: 'C++ Online Compiler | Advanced IDE',
    header: 'C++ Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++',
    versions: [
      'GCC 5.3.0',
      'Zapcc 5.0.0',
      'GCC 7.2.0',
      'GCC 8.1.0',
      'GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    language: 'cpp',
    aceCode: 'c_cpp',
    langDisplayName: 'C++',
    isCompile: true,
    isPlus: true,
    plusVersions: ['GCC 13.2.1'],
    library: LIBRARY.pacman,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.cpp', '.hpp'],
    sampleScript: `/* 
Welcome to JDoodle!

You can execute code here in 88 languages. Right now you’re in the C++ IDE.

  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.

  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->

  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.

Want to change languages? Try the search bar up the top. 
*/

#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },

  cpp14: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write C++ Version 14 code and run it online with JDoodle's C++ 14 online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write C++ 14 code and run it online with JDoodle's C++ 14 online compiler. Advanced IDE with multifile support."
      },
      {
        name: 'keywords',
        content:
          'Online C++ 14 Compiler - Online C++ 14 Editor - Online C++ 14 IDE - C++ 14 Coding Online - Online C++ 14 Runner -  Share Save C++ 14 online'
      }
    ],
    title: 'C++ Version 14 Online Compiler ',
    advanceTitle: 'Online C++ 14 Code Compiler | Advanced IDE',
    header: 'C++  14 Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++14',
    language: 'cpp14',
    aceCode: 'c_cpp',
    versions: [
      'g++ 14 GCC 5.3.0',
      'g++ 14 GCC 7.2.0',
      'g++ 14 GCC 8.1.0',
      'g++ 14 GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    langDisplayName: 'C++ 14',
    isCompile: true,
    isPlus: true,
    plusVersions: ['GCC 13.2.1'],
    library: LIBRARY.pacman,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.cpp', '.hpp'],
    sampleScript: `#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },

  cpp17: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write C++ 17 code and run it online with JDoodle's C++ 17 online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write C++ 17 code and run it online with JDoodle's C++ 17 online compiler. Advanced IDE with multifile support."
      },
      {
        name: 'keywords',
        content:
          'Online C++ 17 Compiler - Online C++ 17 Editor - Online C++ 17 IDE - C++ 17 Coding Online - Online C++ 17 Runner -  Share Save C++ 17 online'
      }
    ],
    title: 'Online C++ 17 Code Compiler',
    advanceTitle: 'Online C++ 17 Code Compiler | Advanced IDE',
    header: 'C++  17 Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++17',
    language: 'cpp17',
    aceCode: 'c_cpp',
    versions: ['g++ 17 GCC 9.1.0', 'g++ 17 GCC 11.1.0', 'g++ 17 GCC 13.2.1'],
    langDisplayName: 'C++ 17',
    isCompile: true,
    isPlus: true,
    plusVersions: ['g++ 17 GCC 13.2.1'],
    library: LIBRARY.pacman,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.cpp', '.hpp'],
    sampleScript: `#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },
  php: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write PHP code and run it online with JDoodle's PHP online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write PHP code and run it online with JDoodle's PHP online compiler. JDoodle's AI powered online Advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'PHP Online Editor - PHP Online IDE -  PHP Coding Online - Share Save PHP Program online'
      }
    ],
    title: 'PHP Code Compiler | Online PHP Editor',
    advanceTitle: 'PHP Code Compiler - Advanced IDE',
    header: 'PHP Code',
    fileName: 'php',
    canonicalPath: 'php-online-editor',
    language: 'php',
    versions: ['5.6.16', '7.1.11', '7.2.5', '7.3.10', '8.0.13', '8.2.12'],
    langDisplayName: 'PHP',
    isCompile: false,
    isPlus: true,
    plusVersions: ['8.2.12'],
    library: LIBRARY.composer,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.php'],
    sampleScript: `<?php

    $x=10;
    $y=25;
    $z=$x+$y;

    $msg = 'Sum of x+y = ';

    print($msg.$z);

?>`
  },
  perl: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Perl code and run it online with JDoodle's Perl online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'PERL Online Editor - PERL Online IDE -  PERL Coding Online - Share Save PERL Program online'
      }
    ],
    title: 'Perl Online Compiler | Perl Editor',
    header: 'PERL Code',
    fileName: 'pl',
    canonicalPath: 'execute-perl-online',
    language: 'perl',
    versions: ['5.22.0', '5.26.1', '5.26.2', '5.30.0', '5.34.0', '5.38.0'],
    langDisplayName: 'Perl',
    isCompile: false,
    isPlus: true,
    plusVersions: ['5.38.0'],
    library: LIBRARY.cpan,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.pl'],
    sampleScript: `
    use strict;
    use warnings;
    
    my $x = 10;
    my $y = 25;
    my $z = $x + $y;
    print "x + y = $z\n";`
  },

  python2: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Python code and run it online with JDoodle's Python online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write Python code and run it online with JDoodle's Python Online Compiler - Advanced IDE. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      }
    ],
    title: 'Online Python Compiler',
    advanceTitle: 'Online Python Compiler - Advanced IDE',
    header: 'Python2 Code',
    fileName: 'py',
    canonicalPath: 'python-programming-online',
    language: 'python2',
    versions: ['2.7.11', '2.7.15', '2.7.16', '2.7.18'],
    aceCode: 'python',
    langDisplayName: 'Python 2',
    isCompile: false,
    isPlus: true,
    library: LIBRARY.pip,
    plusVersions: ['2.7.18'],
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.py'],
    sampleScript: `x=10;
y=25;
z=x+y;

print "Sum of x+y =", z;`
  },

  python3: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Python 3 code and run it online with JDoodle's Python 3 online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write Python 3 code and run it online with JDoodle's Python 3 Online Compiler - Advanced IDE. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      }
    ],
    title: 'Online Python 3 Compiler',
    advanceTitle: 'Online Python 3 Compiler - Advanced IDE',
    header: 'Python3 Code',
    fileName: 'py',
    canonicalPath: 'python3-programming-online',
    language: 'python3',
    versions: ['3.5.1', ' 3.6.3', '3.6.5', '3.7.4', '3.9.9', '3.11.5'],
    langDisplayName: 'Python 3',
    aceCode: 'python',
    isCompile: false,
    isPlus: true,
    plusVersions: ['3.11.5'],
    library: LIBRARY.pip3,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.py'],
    sampleScript: `# Welcome to JDoodle!
#
# You can execute code here in 88 languages. Right now you’re in the Python3 IDE.
#
#  1. Click the orange Execute button ️▶ to execute the sample code below and see how it works.
#  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->
#  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.
#
# Want to change languages? Try the search bar up the top.

x=10
y=25
z=x+y

print ("sum of x+y =", z)
`
  },

  ruby: {
    metaTags: [
      {
        name: 'description',
        content: "Write, compile and debug ruby code with JDoodle's Ruby online editor"
      },
      {
        name: 'advanceDescription',
        content:
          "Write, compile and debug ruby code with JDoodle's Ruby online editor.  Access our multifile support features with our Advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'RUBY Online Editor - RUBY Online IDE -  RUBY Coding Online - Share Save RUBY Program online'
      }
    ],
    title: 'Ruby Online Compiler | Online Ruby Editor',
    advanceTitle: 'Ruby Online Compiler - Advanced IDE',
    header: 'RUBY Code',
    fileName: 'rb',
    canonicalPath: 'execute-ruby-online',
    language: 'ruby',
    versions: ['2.2.4', '2.4.2p198', '2.5.1p57', '2.6.5', '3.0.2', '3.0.6'],
    langDisplayName: 'Ruby',
    isCompile: false,
    isPlus: true,
    plusVersions: ['3.0.6'],
    library: LIBRARY.rubygems,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.rb'],
    sampleScript: `x = 10;
y = 25;
z = x+y;

print "Sum of x + y = ", z;`
  },

  go: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's online GO compiler allows you to write, run & share Go lang code. Online Go Lang IDE"
      },
      {
        name: 'keywords',
        content:
          'Go Online Editor - Go Online IDE -  Go Coding Online - Share Save Go Program online'
      }
    ],
    title: 'Online Go Lang Compiler',
    header: 'Go Code',
    fileName: 'go',
    canonicalPath: 'execute-go-online',
    language: 'go',
    versions: ['1.5.2', '1.9.2', '1.10.2', '1.13.1', '1.17.3', '1.21.4'],
    langDisplayName: 'GO Lang',
    isCompile: false,
    sampleScript: `package main

import "fmt"

func main() {
    x:= 25
    y:=10

    z:= x + y

    fmt.Printf("Sum of x + y = %d", z)
}`
  },

  scala: {
    metaTags: [
      {
        name: 'description',
        content: "Write, execute & share Scala code online with JDoodle's Online Scala Compiler"
      },
      {
        name: 'advanceDescription',
        content:
          "Write, execute & share Scala code online with JDoodle's Online Scala Compiler.  Access our multifile support features with our Advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'Online Scala Compiler - Online Scala Editor - Online Scala IDE - Scala Coding Online - Online Scala Runner -  Share Save Scala online'
      }
    ],
    title: 'Online Scala Compiler',
    advanceTitle: 'Online Scala Compiler - Advanced IDE',
    header: 'Scala Code',
    fileName: 'scala',
    canonicalPath: 'compile-scala-online',
    language: 'scala',
    versions: ['2.12.0', '2.12.4', '2.12.5', '2.13.0', '2.13.6', '2.13.12'],
    langDisplayName: 'Scala',
    isCompile: false,
    isPlus: true,
    plusVersions: ['2.13.12'],
    library: LIBRARY.maven,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.scala'],
    sampleScript: `
object JDoodle {
  def add(x:Int, y:Int) = x + y;

  def main(args: Array[String]) {
    print("sum of x + y = " + add(25,10));
  }
}`
  },

  bash: {
    metaTags: [
      {
        name: 'description',
        content:
          "Master shell scripting and programming with JDoodle's powerful online IDE. Write and execute Bash scripts effortlessly in our user-friendly environment."
      },
      {
        name: 'keywords',
        content:
          'Test Bash Script Online - Online Bash Script Tester -  Bash Scripting Online - Test Shell Script Online - Share Save Bash Script online'
      }
    ],
    title: 'Online Bash Shell Editor',
    header: 'Bash Script',
    fileName: 'sh',
    canonicalPath: 'test-bash-shell-script-online',
    language: 'bash',
    versions: ['4.3.42', '4.4.12', '4.4.19', '5.0.011', '5.1.12', '5.2.15'],
    langDisplayName: 'Bash Shell',
    isCompile: false,
    isPlus: true,
    plusVersions: ['5.2.15'],
    library: LIBRARY.ArchBash,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.sh'],
    sampleScript: `#!/bin/bash

echo "welcome to JDoodle"`
  },

  sql: {
    metaTags: [
      {
        name: 'description',
        content:
          "Execute and debug SQL queries effortlessly with JDoodle's advanced online SQL compiler. Enhance your SQL programming skills in our intuitive, user-friendly environment."
      },
      {
        name: 'keywords',
        content:
          'Online SQL Editor - Online SQL Scripting -  Practice SQL Online - Share Save SQL Program online'
      }
    ],
    title: 'Online SQL Compiler | JDoodle',
    header: 'SQL Code',
    fileName: 'sql',
    canonicalPath: 'execute-sql-online',
    language: 'sql',
    versions: [
      'SQLite 3.9.2',
      'SQLite 3.21.0',
      'SQLite 3.23.1',
      'SQLite 3.29.0',
      'SQLite 3.37.0',
      'SQLite 3.44.0'
    ],
    langDisplayName: 'SQL',
    isCompile: false,
    sampleScript: `
        create table calc(x int, y int);

        insert into calc values(10, 25);

        select x,y, (x+y) from calc;`
  },

  pascal: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Pascal code seamlessly with JDoodle's online Pascal compiler. Enjoy a robust, user-friendly platform for all your Pascal programming needs."
      },
      {
        name: 'keywords',
        content:
          'Online Pascal Compiler - Online Pascal Editor - Online Pascal IDE - Pascal Coding Online - Online Pascal Runner -  Share Save Pascal online'
      }
    ],
    title: 'Online Pascal Compiler',
    header: 'Pascal Code',
    fileName: 'pas',
    canonicalPath: 'execute-pascal-online',
    language: 'pascal',
    versions: ['fpc 3.0.0', 'fpc-3.0.2', 'fpc-3.0.4', 'fpc-3.2.2'],
    langDisplayName: 'Pascal',
    isCompile: true,
    sampleScript: `Program AddNums(output);
    var x,y,z:integer;
    begin

    x:=10;
    y:=25;
    z:=x+y;
    writeln('The sum of x+y is ', z);
end.`
  },

  csharp: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and execute C# code effortlessly with JDoodle's online C# compiler. Enhance your coding efficiency in our intuitive, user-friendly platform."
      },
      {
        name: 'advanceDescription',
        content:
          "Write C# code and run it online with JDoodle's C# online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Online C# Compiler - Online C# Editor - Online C# IDE - C# Coding Online - Online C# Runner -  Share Save C# online'
      }
    ],
    title: 'Online C# Compiler (Editor)',
    advanceTitle: 'Online C# Compiler',
    header: 'C# Code',
    fileName: 'cs',
    canonicalPath: 'compile-c-sharp-online',
    language: 'csharp',
    versions: [
      'mono 4.2.2',
      'mono 5.0.0',
      'mono 5.10.1',
      'mono 6.0.0',
      'mono-6.12.0',
      'dotnet: 7.0.13'
    ],
    langDisplayName: 'C#',
    isCompile: true,
    isPlus: true,
    plusVersions: ['dotnet: 7.0.13'],
    library: LIBRARY.nuget,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.cs', '.csproj'],
    sampleScript: `using System;

class Program
{
    static void Main() {
        int x = 10;
        int y = 25;
        int z = x + y;

        Console.Write("Sum of x + y = "+ z);
    }
}`
  },
  vbn: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run VB.Net code seamlessly with JDoodle's Visual Basic online compiler. Experience efficient, user-friendly programming on our robust platform."
      },
      {
        name: 'keywords',
        content:
          'Online VB.Net Compiler - Online VB.Net Editor - Online VB.Net IDE - VB.Net Coding Online - Online VB.Net Runner -  Share Save VB.Net online'
      }
    ],
    title: 'Online VB.Net Compiler',
    header: 'VB.Net Code',
    fileName: 'vb',
    canonicalPath: 'compile-vb-dot-net-online',
    language: 'vbn',
    versions: [
      'mono 4.0.1',
      'mono 4.6',
      'mono 5.10.1',
      'mono 6.0.0',
      'mono 6.12.0',
      'dotnet: 7.0.13'
    ],
    langDisplayName: 'VB.Net',
    isCompile: true,
    isPlus: true,
    plusVersions: ['dotnet: 7.0.13'], // need to introduce new version with new version index
    library: LIBRARY.nuget,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.vb', '.vbproj'],
    sampleScript: `Imports System

Public Class Test
    Public Shared Sub Main()
        Dim x, y as Integer
        x = 10
        y = 25
        System.Console.WriteLine("Sum of x and y = " & (x+y))
    End Sub
End Class`
  },

  haskell: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Haskell code seamlessly with JDoodle's online Haskell compiler. Experience efficient, functional programming on our user-friendly platform."
      },
      {
        name: 'keywords',
        content:
          'Online Haskell Compiler - Online Haskell Editor - Online Haskell IDE - Haskell Coding Online - Online Haskell Runner -  Share Save Haskell online'
      }
    ],
    title: 'Online Haskell Runner',
    header: 'Haskell Code',
    fileName: 'hs',
    canonicalPath: 'execute-haskell-online',
    language: 'haskell',
    versions: ['ghc 7.10.3', 'ghc 8.2.1', 'ghc 8.2.2', 'ghc 8.6.5', 'ghc 9.0.1', 'ghc 9.0.2'],
    langDisplayName: 'Haskell',
    isCompile: true,
    sampleScript: `addMe :: Integer -> Integer -> Integer
addMe x y = x + y

main :: IO ()
main =  do
putStr "Sum of x + y = "
print(addMe 10 25)`
  },

  objc: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and execute Objective-C code effortlessly with JDoodle's online Objective-C compiler. Experience a robust, user-friendly programming platform."
      },
      {
        name: 'keywords',
        content:
          'Online Objective C Compiler - Online Objective C Editor - Online Objective C IDE - Objective C Coding Online - Online Objective C Runner -  Share Save Objective C online'
      }
    ],
    title: 'Online Objective C Compiler',
    header: 'Objective C Code',
    fileName: 'm',
    canonicalPath: 'execute-objective-c-online',
    language: 'objc',
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    langDisplayName: 'Objective C',
    aceCode: 'objectivec',
    isCompile: true,
    sampleScript: `#import <Foundation/Foundation.h>

int main (int argc, const char * argv[])
{
    NSAutoreleasePool * pool = [[NSAutoreleasePool alloc] init];

    int x = 10;
    int y = 25;

    NSLog(@"Sum of x + y = %i", (x+y));

    [pool drain];
    return 0;
}`
  },

  swift: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Swift code effortlessly with JDoodle's online Swift compiler. Enhance your Swift programming experience in a user-friendly environment."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile and run Swift code effortlessly with JDoodle's online Swift compiler.  Access our multifile support features with our Advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'Online Swift Compiler - Online Swift Editor - Online Swift IDE - Swift Coding Online - Online Swift Runner -  Share Save Swift online'
      }
    ],
    title: 'Swift Online Compiler',
    advanceTitle: 'Swift Online Compiler - Advanced IDE',
    header: 'Swift Code',
    fileName: 'swift',
    canonicalPath: 'execute-swift-online',
    language: 'swift',
    versions: ['2.2', '3.1.1', '4.1', '5.1', '5.5', '5.9.1'],
    langDisplayName: 'Swift',
    isCompile: true,
    isPlus: true,
    plusVersions: ['5.9.1'],
    library: LIBRARY.swift,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.swift'],
    sampleScript: `let x = 10
let y = 25
print("Sum of x and y = ", (x+y))`
  },
  groovy: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write, compile, and execute Groovy code seamlessly with JDoodle's online Groovy editor and compiler. Experience a powerful, user-friendly programming platform."
      },
      {
        name: 'advanceDescription',
        content:
          "Write, compile, and execute Groovy code seamlessly with JDoodle's online Groovy compiler and advanced IDE. Experience a powerful, user-friendly programming platform."
      },
      {
        name: 'keywords',
        content:
          'Online Groovy Compiler - Online Groovy Editor - Online Groovy IDE - Groovy Coding Online - Online Groovy Runner -  Share Save Groovy online'
      }
    ],
    title: 'Online Groovy Editor',
    header: 'Groovy Code',
    fileName: 'groovy',
    canonicalPath: 'execute-groovy-online',
    language: 'groovy',
    versions: [
      '2.4.6 JVM: 1.7.0_99',
      '2.4.12 JVM: 9.0.1',
      '2.4.15 JVM: 10.0.1',
      '2.5.8 JVM: 11.0.4',
      '3.0.9 JVM: 17.0.1',
      '4.0.15 JVM: 21'
    ],
    langDisplayName: 'Groovy',
    isCompile: false,
    isPlus: true,
    plusVersions: ['4.0.15 JVM: 21'],
    library: LIBRARY.maven,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.groovy'],
    sampleScript: `/* 
Welcome to JDoodle!

You can execute code here in 88 languages. Right now you’re in the Groovy IDE.

  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.

  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->

  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.

Want to change languages? Try the search bar up the top. 
*/

def x = 10
def y = 25
int z = x + y

println("x + y = " + z)`
  },
  fortran: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Fortran code effortlessly with JDoodle's online Fortran compiler. Enhance your programming experience on a robust, user-friendly platform."
      },
      {
        name: 'keywords',
        content:
          'Online Fortran Compiler - Online Fortran Editor - Online Fortran IDE - Fortran Coding Online - Online Fortran Runner -  Share Save Fortran online'
      }
    ],
    title: 'Online Fortran Compiler',
    header: 'Fortran Code',
    fileName: 'f95',
    canonicalPath: 'execute-fortran-online',
    language: 'fortran',
    versions: ['GNU 5.3.0', 'GNU 7.2.0', 'GNU 8.1.0', 'GNU 9.1.0', 'GNU 11.1.0', 'GNU 13.2.1'],
    langDisplayName: 'Fortran',
    isCompile: true,
    sampleScript: `program sum
    REAL X,Y,Z
    X = 10
    Y= 25
    Z = X + Y
    PRINT *,"sum of x + y = ", Z
end program sum`
  },
  brainfuck: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Brainfuck code effortlessly with JDoodle's online Brainfuck compiler. Experience a user-friendly, efficient platform for Brainfuck programming."
      },
      {
        name: 'keywords',
        content:
          'Online Brainfuck Compiler - Online Brainfuck Editor - Online Brainfuck IDE - Brainfuck Coding Online - Online Brainfuck Runner -  Share Save Brainfuck online'
      }
    ],
    title: 'Online Brainfuck Compiler',
    header: 'B******K Code',
    fileName: 'bf',
    canonicalPath: 'execute-b******k-online',
    language: 'brainfuck',
    versions: ['bfc-0.1'],
    langDisplayName: 'B******K',
    isCompile: true,
    sampleScript: `>+++++++++[<++++++++>-]<.>+++++++[<++++>-]<+.+++++++..+++.>>>++++++++[<++++>-]
<.>>>++++++++++[<+++++++++>-]<---.<<<<.+++.------.--------.>>+.>++++++++++.`
  },

  lua: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Lua code effortlessly with JDoodle's online Lua compiler. Enjoy a user-friendly, efficient platform for all your Lua programming needs."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile and run Lua code effortlessly with JDoodle's online Lua compiler & Advanced IDE. User-friendly, efficient platform for all your Lua programming needs."
      },
      {
        name: 'keywords',
        content:
          'Online Lua Compiler - Online Lua Editor - Online Lua IDE - Lua Coding Online - Online Lua Runner -  Share Save Lua online'
      }
    ],
    title: 'Online Lua Compiler',
    advanceTitle: 'Online Lua Compiler - Advanced IDE',
    header: 'Lua Code',
    fileName: 'lua',
    canonicalPath: 'execute-lua-online',
    language: 'lua',
    versions: ['5.3.2', '5.3.4', '5.3.5', '5.4.3', '5.4.6'],
    langDisplayName: 'Lua',
    isCompile: false,
    isPlus: true,
    plusVersions: ['5.4.6'],
    library: LIBRARY.luaRocks,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.lua'],
    sampleScript: `-- Welcome to JDoodle!
--
-- You can execute code here in 88 languages. Right now you’re in the Lua IDE. 
--
--  1. Click the orange Execute button ️▶ to execute the sample code below and see how it works.
--  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->
--  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.
--
-- Want to change languages? Try the search bar up the top.

local x = 10
local y = 25
local z = x + y

print("Sum of x+y =",z)`
  },

  tcl: {
    metaTags: [
      {
        name: 'description',
        content:
          "Experience JDoodle's free online Tcl compiler and editor. Write, run, and debug Tcl code directly in your browser. No setup required. Ideal for quick coding and learning Tcl."
      },
      {
        name: 'keywords',
        content:
          'Online TCL Compiler - Online TCL Editor - Online TCL IDE - TCL Coding Online - Online TCL Runner -  Share Save TCL online'
      }
    ],
    title: 'Free Online Tcl Compiler & Editor',
    header: 'TCL Code',
    fileName: 'tcl',
    canonicalPath: 'execute-tcl-online',
    language: 'tcl',
    versions: ['8.6', '8.6.7', '8.6.8', '8.6.9', '8.6.12', '8.6.13'],
    langDisplayName: 'TCL',
    isCompile: false,
    sampleScript: `set x 10
set y 25
set z [expr $x + $y]

puts "Sum of x +y =  $z"`
  },

  hack: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Hack code seamlessly with JDoodle's online Hack compiler. Experience a robust, user-friendly platform for all your Hack programming needs."
      },
      {
        name: 'keywords',
        content:
          'Online Hack Compiler - Online Hack Editor - Online Hack IDE - Hack Coding Online - Online Hack Runner -  Share Save Hack online'
      }
    ],
    title: 'Online Hack Compiler',
    header: 'Hack Code',
    fileName: 'hack',
    canonicalPath: 'execute-hack-online',
    language: 'hack',
    versions: ['HipHop VM 3.13.0'],
    langDisplayName: 'Hack',
    isCompile: true,
    sampleScript: `<?hh

    $x=10;
    $y=25;
    $z=$x+$y;

    $msg = 'Sum of x+y = ';

    echo($msg.$z);`
  },
  rust: {
    metaTags: [
      {
        name: 'description',
        content:
          "Experience JDoodle's free online Rust compiler and IDE. Write, run, and debug Rust code directly in your browser. No setup needed. Ideal for learning and quick Rust programming tasks."
      },
      {
        name: 'keywords',
        content:
          'Online RUST Compiler - Online RUST Editor - Online RUST IDE - RUST Coding Online - Online RUST Runner -  Share Save RUST online'
      }
    ],
    title: 'Rust Online Compiler',
    header: 'RUST Code',
    fileName: 'rs',
    canonicalPath: 'execute-rust-online',
    language: 'rust',
    versions: ['1.10.0', '1.21.0', '1.25.0', '1.38.0', '1.56.1', '1.73.0'],
    langDisplayName: 'RUST',
    isCompile: true,
    sampleScript: `fn main() {
    let x = 10;
    let y = 25;

    let z = x + y;

    println!("Sum of x and y = {} ",z);
}`
  },
  d: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and edit D language code with JDoodle's online D compiler and editor. Experience efficient programming in D with our user-friendly platform."
      },
      {
        name: 'keywords',
        content:
          'Online D Compiler - Online D Editor - Online D IDE - D Coding Online - Online D Runner -  Share Save D online'
      }
    ],
    title: 'Online D Compiler',
    header: 'D Code',
    fileName: 'd',
    canonicalPath: 'execute-d-online',
    language: 'd',
    versions: [
      'DMD64 D Compiler v2.071.1',
      'DMD64 D Compiler v2.088',
      'DMD64 D Compiler v2.098',
      'DMD64 D Compiler v2.105.2'
    ],
    langDisplayName: 'D',
    isCompile: true,
    sampleScript: `import std.stdio;

void main(string[ ] args) {

    int x = 10;
    int y = 25;

    int z = x + y;

    writeln("x + y = ", z);
}`
  },
  ada: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Ada code effortlessly with JDoodle's online Ada compiler. Enjoy a user-friendly, efficient platform for all your Ada programming needs."
      },
      {
        name: 'keywords',
        content:
          'Online Ada Compiler - Online Ada Editor - Online Ada IDE - Ada Coding Online - Online Ada Runner -  Share Save Ada online'
      }
    ],
    title: 'Ada Compiler Online',
    header: 'Ada Code',
    fileName: 'adb',
    canonicalPath: 'execute-ada-online',
    language: 'ada',
    versions: [
      'GNATMAKE 6.1.1',
      'GNATMAKE 7.2.0',
      'GNATMAKE 8.1.0',
      'GNATMAKE 9.1.0',
      'GNATMAKE 11.1.0',
      'GNATMAKE 13.2.1'
    ],
    langDisplayName: 'Ada',
    isCompile: true,
    sampleScript: `with Ada.Text_IO; use Ada.Text_IO;
with Ada.Integer_Text_IO; use Ada.Integer_Text_IO;
procedure jdoodle is
    X: Natural := 10;
    Y: Natural := 25;
    Z: Natural := X + Y;
begin
    Put("X + Y = ");
    Put(Z);
end jdoodle;`
  },
  r: {
    metaTags: [
      {
        name: 'description',
        content:
          "Access and edit R code with JDoodle's online R compiler and editor. Compile and execute R scripts efficiently in a user-friendly environment."
      },
      {
        name: 'advanceDescription',
        content:
          "Access and edit R code with JDoodle's online R compiler and editor. Access our multifile support features with our Advanced IDE"
      },
      {
        name: 'keywords',
        content: 'R Online Editor - R Online IDE -  R Coding Online - Share Save R Program online'
      }
    ],
    title: 'R Online Compiler | Run R Code Online',
    advanceTitle: 'R Online Compiler - Advanced IDE',
    header: 'R Code',
    fileName: 'r',
    canonicalPath: 'execute-r-online',
    language: 'r',
    versions: ['3.3.1', '3.4.2', '3.5.0', '3.6.1', '4.1.2', '4.3.2'],
    langDisplayName: 'R Language',
    isCompile: false,
    isPlus: true,
    plusVersions: ['4.3.2'],
    library: LIBRARY.cran,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.r'],
    sampleScript: `x <- 10
y <- 25
z <- sum(x,y)

cat("x + y = ", z)`
  },
  freebasic: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run FreeBASIC code effortlessly with JDoodle's online FreeBASIC compiler. Enjoy a user-friendly, efficient platform for all your FreeBASIC programming needs."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile and run FreeBASIC code effortlessly with JDoodle's advanced online FreeBASIC compiler. JDoodle's AI powered online coding platform"
      },
      {
        name: 'keywords',
        content:
          'Online FREE BASIC Compiler - Online FREE BASIC Editor - Online FREE BASIC IDE - FREE BASIC Coding Online - Online FREE BASIC Runner -  Share Save FREE BASIC online'
      }
    ],
    title: 'Online Free Basic Compiler',
    advanceTitle: 'Online Free Basic Compiler - Advanced Online IDE',
    header: 'FREE BASIC Code',
    fileName: 'bas',
    canonicalPath: 'execute-freebasic-online',
    language: 'freebasic',
    versions: ['1.05.0', '1.07.1', '1.08.1', '1.10.0'],
    langDisplayName: 'FREE BASIC',
    isCompile: true,
    isPlus: true,
    plusVersions: ['1.10.0'],
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.bas'],
    sampleScript: `Dim x as Integer = 10
DIM y as Integer = 25
DIM z as Integer
z = x + y

print "Sum of x + y = "; z`
  },
  verilog: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Verilog code effortlessly with JDoodle's online Verilog compiler. Experience a user-friendly, efficient platform for Verilog programming."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile and run Verilog code effortlessly with JDoodle's online Verilog compiler - Advanced IDE. Experience an efficient platform for Verilog programming."
      },
      {
        name: 'keywords',
        content:
          'Online VERILOG Compiler - Online VERILOG Editor - Online VERILOG IDE - VERILOG Coding Online - Online VERILOG Runner -  Share Save VERILOG online'
      }
    ],
    title: 'Online Verilog Compiler',
    advanceTitle: 'Online Verilog Compiler - Advanced IDE',
    header: 'VERILOG Code',
    fileName: 'v',
    canonicalPath: 'execute-verilog-online',
    language: 'verilog',
    versions: ['10.1', '10.2', '10.3', '11', '12'],
    langDisplayName: 'VERILOG',
    isCompile: true,
    isPlus: true,
    plusVersions: ['12'],
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.v'],
    sampleScript: `// Welcome to JDoodle!
//
// You can execute code here in 88 languages. Right now you’re in the Verilog IDE. 
//
//  1. Click the orange Execute button ️▶ to execute the sample code below and see how it works.
//  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->
//  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.
//
// Want to change languages? Try the search bar up the top.

module jdoodle;
    initial begin
        $display ("Welcome to JDoodle!!!");
        $finish;
    end
endmodule`
  },
  cobol: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run COBOL code online with JDoodle's easy-to-use COBOL compiler. Experience efficient and user-friendly COBOL programming."
      },
      {
        name: 'advanceDescription',
        content:
          "Online Cobol Compiler. Run, share & execute Cobol code using JDoodle's online Advanced IDE. JDoodle's AI powered IDE."
      },
      {
        name: 'keywords',
        content:
          'Online COBOL Compiler - Online COBOL Editor - Online COBOL IDE - COBOL Coding Online - Online COBOL Runner -  Share Save COBOL online'
      }
    ],
    title: 'Cobol Compiler Online',
    header: 'COBOL Code',
    fileName: 'cobc',
    canonicalPath: 'execute-cobol-online',
    language: 'cobol',
    versions: [
      'GNU COBOL 2.0.0',
      'GNU COBOL 2.2.0',
      'GNU COBOL 3.0',
      'GNU COBOL 3.1.2',
      'GNU COBOL 3.2.0'
    ],
    langDisplayName: 'COBOL',
    isCompile: true,
    isPlus: true,
    plusVersions: ['GNU COBOL 3.2.0'],
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.cobc'],
    sampleScript: `*> Welcome to JDoodle!
*>
*> You can execute code here in 88 languages. Right now you’re in the COBOL IDE.
*>
*>  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.
*>  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->
*>  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.
*>
*> Want to change languages? Try the search bar up the top.

IDENTIFICATION DIVISION.
PROGRAM-ID. HELLO-WORLD.
DATA DIVISION.
    WORKING-STORAGE SECTION.
        77 X PIC 99.
        77 Y PIC 99.
        77 Z PIC 99.
PROCEDURE DIVISION.
    SET X TO 10.
    SET Y TO 25.
    ADD X Y GIVING Z.
    DISPLAY "X + Y = "Z.
STOP RUN.
`
  },
  dart: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and edit Dart code with JDoodle's online Dart compiler and editor. Experience efficient Dart programming in a user-friendly platform."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile and edit Dart code with JDoodle's online Advanced IDE. Online Dart Compiler and Editor."
      },
      {
        name: 'keywords',
        content:
          'DART Online Editor - DART Online IDE -  DART Coding Online - Share Save DART Program online'
      }
    ],
    title: 'Online Dart Compiler',
    advanceTitle: 'Online Dart Compiler - Advanced Online IDE',
    header: 'DART Code',
    fileName: 'dart',
    canonicalPath: 'execute-dart-online',
    language: 'dart',
    versions: ['1.18.0', '1.24.2', '1.24.3', '2.5.1', '2.14.4', '3.0.7'],
    langDisplayName: 'Dart',
    isCompile: false,
    isPlus: true,
    plusVersions: ['3.0.7'],
    library: LIBRARY.pub,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.dart', '.yaml'],
    sampleScript: `void main(){
    var x = 10;
    var y = 25;
    var z = x + y;
    print("x + y = $z");
}`
  },

  yabasic: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Ya Basic code effortlessly with JDoodle's online Ya Basic compiler. Enjoy a user-friendly, efficient platform for all your Ya Basic programming needs"
      },
      {
        name: 'keywords',
        content:
          'YABASIC Online Editor - YABASIC Online IDE -  YABASIC Coding Online - Share Save YABASIC Program online'
      }
    ],
    title: 'Online Ya Basic Compiler',
    header: 'YABASIC Code',
    fileName: 'bas',
    canonicalPath: 'execute-yabasic-online',
    language: 'yabasic',
    versions: ['2.769', '2.84.1', '2.90.4'],
    langDisplayName: 'YaBasic',
    isCompile: false,
    sampleScript: `let x = 10
let y = 25
let z = x + y

print "sum of x + y = ", z`
  },

  clojure: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Clojure code online with JDoodle's user-friendly Clojure compiler. Enhance your Clojure coding experience in an efficient environment."
      },
      {
        name: 'keywords',
        content:
          'Clojure Online Editor - Clojure Online IDE -  Clojure Coding Online - Share Save Clojure Program online'
      }
    ],
    title: 'Online Clojure Compiler',
    header: 'Clojure Code',
    fileName: 'clj',
    canonicalPath: 'execute-clojure-online',
    language: 'clojure',
    versions: ['1.8.0', '1.9.0', '1.10.1', '1.10.3', '1.11.1'],
    langDisplayName: 'Clojure',
    isCompile: false,
    sampleScript: `(ns clojure.examples.hello
(:gen-class))

(defn sum-of-numbers [x y]
(println (format "x + y = %d" (+ x y))))

(sum-of-numbers 10 25)`
  },
  nodejs: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile, run, and edit Node.js code online with JDoodle's Node.js online compiler and editor. Experience efficient Node.js programming in a user-friendly platform."
      },
      {
        name: 'advanceDescription',
        content:
          "Compile, run, and edit Node.js code online with JDoodle's Node.js online compiler and editor. Experience efficient Node.js programming with JDoodle's Advanced IDE."
      },
      {
        name: 'keywords',
        content:
          'NodeJS Online Editor - NodeJS Online IDE -  NodeJS Coding Online - Share Save NodeJS Program online'
      }
    ],
    title: 'NodeJS Online Editor',
    advanceTitle: 'NodeJS Online Editor - Advanced IDE',
    header: 'NodeJS Code',
    fileName: 'js',
    canonicalPath: 'execute-nodejs-online',
    language: 'nodejs',
    versions: ['6.3.1', '9.2.0', '10.1.0', '12.11.1', '17.1.0', '20.9.0', '21.2.0'],
    langDisplayName: 'NodeJS',
    isCompile: false,
    isPlus: true,
    plusVersions: ['21.2.0'],
    library: LIBRARY.npm,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.js', '.json'],
    sampleScript: `/* 
Welcome to JDoodle!

You can execute code here in 88 languages. Right now you’re in the Nodejs IDE.

  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.

  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->

  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.

Want to change languages? Try the search bar up the top. 
*/
var x = 10;
var y = 25;
var z = x + y;

console.log("x + y = " + z);`
  },
  scheme: {
    metaTags: [
      {
        name: 'description',
        content:
          "Edit Scheme code online with JDoodle's user-friendly Scheme editor. Write and manage your Scheme programming in an efficient environment."
      },
      {
        name: 'keywords',
        content:
          'Scheme Online Editor - Scheme Online IDE -  Scheme Coding Online - Share Save Scheme Program online'
      }
    ],
    title: 'Scheme Online Editor',
    header: 'Scheme Code',
    fileName: 'sc',
    canonicalPath: 'execute-scheme-online',
    language: 'scheme',
    versions: ['Gauche 0.9.4', 'Gauche 0.9.5', 'Gauche 0.9.8', 'Gauche 0.9.10', 'Gauche 0.9.13'],
    langDisplayName: 'Scheme',
    isCompile: false,
    sampleScript: `(define (sum a b)
(display "x + y = ")
(display (+ a b)))

(sum 10 25)
(newline)`
  },
  forth: {
    metaTags: [
      {
        name: 'description',
        content:
          'Access Forth online editor, compiler, and IDE on JDoodle. Write, compile, and execute Forth code in a user-friendly environment.Access Forth online editor, compiler, and IDE on JDoodle. Write, compile, and execute Forth code in a user-friendly environment.'
      },
      {
        name: 'keywords',
        content:
          'Forth Online Editor - Forth Online IDE -  Forth Coding Online - Share Save Forth Program online'
      }
    ],
    title: 'Forth Online Editor',
    header: 'Forth Code',
    fileName: 'fth',
    canonicalPath: 'execute-forth-online',
    language: 'forth',
    versions: ['gforth 0.7.3', '4th 3.64'],
    langDisplayName: 'Forth',
    isCompile: false,
    sampleScript: '.( sum of 10 and 25 is ) 10 25 + .'
  },

  prolog: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and edit Prolog code online with JDoodle's user-friendly Prolog compiler and editor. Enhance your Prolog programming experience in an efficient platform."
      },
      {
        name: 'keywords',
        content:
          'Prolog Online Editor - Prolog Online IDE -  Prolog Coding Online - Share Save Prolog Program online'
      }
    ],
    title: 'Prolog Online Compiler',
    header: 'Prolog Code',
    fileName: 'pg',
    canonicalPath: 'execute-prolog-online',
    language: 'prolog',
    versions: ['GNU Prolog 1.4.4', 'GNU Prolog 1.4.5', 'GNU Prolog 1.5.0'],
    langDisplayName: 'Prolog',
    isCompile: false,
    sampleScript: `:- initialization(main).
  main :- write('Welcome to JDoodle.com!!').`
  },
  octave: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Matlab code online with JDoodle's Matlab online compiler. Experience efficient Matlab programming in a user-friendly environment."
      },
      {
        name: 'keywords',
        content:
          'Octave Online Editor - Octave Online IDE -  Octave Coding Online - Share Save Octave Program online'
      }
    ],
    title: 'Online Octave (MATLAB) Compiler',
    header: 'Octave Code',
    fileName: 'm',
    canonicalPath: 'execute-octave-matlab-online',
    language: 'octave',
    versions: ['GNU 4.0.0', 'GNU 4.2.1', 'GNU 4.4.0', 'GNU 5.1.0', 'GNU 6.4.0', 'GNU 8.4.0'],
    langDisplayName: 'Octave',
    isCompile: false,
    sampleScript: `vector = (1:1:10);
matrix = [vector ; vector * 5; vector * 10 ]
matrix(1:3, 2:4)`
  },
  coffeescript: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run CoffeeScript code online with JDoodle's user-friendly CoffeeScript compiler. Experience efficient CoffeeScript programming in a convenient platform."
      },
      {
        name: 'keywords',
        content:
          'Online CoffeeScript Compiler - Online CoffeeScript Editor - Online CoffeeScript IDE - CoffeeScript Coding Online - Online CoffeeScript Runner -  Share Save CoffeeScript online'
      }
    ],
    title: 'Online CoffeeScript Compiler',
    header: 'CoffeeScript Code',
    fileName: 'coffee',
    canonicalPath: 'compile-coffeescript-online',
    language: 'coffeescript',
    versions: ['1.11.1', '2.0.0', '2.3.0', '2.4.1', '2.6.1', '2.7.0'],
    langDisplayName: 'CoffeeScript',
    aceCode: 'coffee',
    isCompile: true,
    sampleScript: `x = 10
y = 25
z = x+y

console.log("Sum of ", x , " and ", y , " is ", z)`
  },
  icon: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run Icon code online with JDoodle's user-friendly Icon compiler. Experience efficient Icon programming in a convenient platform."
      },
      {
        name: 'keywords',
        content:
          'Online Icon Compiler - Online Icon Editor - Online Icon IDE - Icon Coding Online - Online Icon Runner -  Share Save Icon online'
      }
    ],
    title: 'Online Icon Compiler',
    header: 'Icon Code',
    fileName: 'icn',
    canonicalPath: 'compile-icon-online',
    language: 'icon',
    versions: ['9.4.3', '9.5.1', '9.5.23'],
    langDisplayName: 'Icon',
    isCompile: true,
    sampleScript: `procedure main()
    x := 10;
    y := 25;
    z := x + y;

    write ("sum of ", x, " and ",y," is ",z);
end`
  },

  fsharp: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile and run F# code online with JDoodle's user-friendly Fsharp online compiler. Best online editor to code with F# programming language."
      },
      {
        name: 'keywords',
        content:
          'Online F# Compiler - Online F# Editor - Online F# IDE - F# Coding Online - Online F# Runner -  Share Save F# online'
      }
    ],
    title: 'F# (FSharp) Online Compiler',
    header: 'F# Code',
    fileName: 'fs',
    canonicalPath: 'compile-fsharp-online',
    language: 'fsharp',
    versions: ['4.1', '4.5.0', '10.2.3'],
    langDisplayName: 'F#',
    isCompile: true,
    sampleScript: `printf "welcome to JDoodle.com"`
  },

  nasm: {
    metaTags: [
      {
        name: 'description',
        content:
          "Free Online Assembly Compiler (NASM). Write & run assembly code using JDoodle's online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online Assembler (NASM) Compiler - Online Assembler (NASM) Editor - Online Assembler (NASM) IDE - Assembler (NASM) Coding Online - Online Assembler (NASM) Runner -  Share Save Assembler (NASM) online'
      }
    ],
    title: 'Online Assembler (NASM) Compiler',
    header: 'Assembler (NASM) Code',
    fileName: 'asm',
    canonicalPath: 'compile-assembler-nasm-online',
    language: 'nasm',
    versions: ['2.11.08', '2.13.01', '2.13.03', '2.14.02', '2.15.05', '2.16.01'],
    langDisplayName: 'Assembler - NASM',
    aceCode: 'assembly_x86',
    isCompile: true,
    isPlus: true,
    plusVersions: ['2.16.01'],
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.asm'],
    sampleScript: `; Welcome to JDoodle!
;
; You can execute code here in 88 languages. Right now you’re in the NASM IDE.
;
;  1. Click the orange Execute button ▶ to execute the sample code below and see how it works.
;  2. Want help writing or debugging code? Type a query into JDroid on the right hand side ---------------->
;  3. Try the menu buttons on the left. Save your file, share code with friends and open saved projects.
;
; Want to change languages? Try the search bar up the top.

section .text

global _start

_start:

    mov     eax, [x]
    sub     eax, '0'
    mov     ebx, [y]
    sub     ebx, '0'
    add     eax, ebx
    add     eax, '0'

    mov     [sum], eax

    mov     ecx, msg
    mov     edx, len
    mov     ebx, 1
    mov     eax, 4
    int     0x80

    mov     ecx, sum
    mov     edx, 1
    mov     ebx, 1
    mov     eax, 4
    int     0x80

    mov     eax, 1
    int     0x80

section .data
    x db '5'
    y db '3'
    msg db  "sum of x and y is "
    len equ $ - msg

segment .bss

    sum resb 1
`
  },

  gccasm: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's Online Assembler (GCC) Compiler offers seamless assembly coding. Compile and run your code instantly for free with JDoodle."
      },
      {
        name: 'keywords',
        content:
          'Online Assembler (GCC) Compiler - Online Assembler (GCC) Editor - Online Assembler (GCC) IDE - Assembler (GCC) Coding Online - Online Assembler (GCC) Runner -  Share Save Assembler (GCC) online'
      }
    ],
    title: 'Online Assembler (GCC) Compiler',
    header: 'Assembler (GCC) Code',
    fileName: 's',
    canonicalPath: 'compile-assembler-gcc-online',
    language: 'gccasm',
    versions: ['GCC 6.2.1', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    langDisplayName: 'Assembler - GCC',
    aceCode: 'assembly_x86',
    isCompile: true,
    sampleScript: `.data
.globl greet
greet:
.string "Welcome to JDoodle"

.text
.global main
main:
    pushq   %rbp
    movq    %rsp,       %rbp
    movq    $greet,     %rdi
    call    puts
    movq    $0,         %rax
    leave
    ret
`
  },

  intercal: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Intercal Compiler - JDoodle's online Intercal Compiler allows you to write, run & share Intercal code right from your browser,"
      },
      {
        name: 'keywords',
        content:
          'Online Intercal Compiler - Online Intercal Editor - Online Intercal IDE - Intercal Coding Online - Online Intercal Runner -  Share Save Intercal online'
      }
    ],
    title: 'Online Intercal Compiler',
    header: 'Intercal Code',
    fileName: 'i',
    canonicalPath: 'compile-intercal-online',
    language: 'intercal',
    versions: ['0.31'],
    langDisplayName: 'Intercal',
    isCompile: true,
    sampleScript: `DO ,1 <- #19
PLEASE DO ,1 SUB #1 <- #22
DO ,1 SUB #2 <- #68
DO ,1 SUB #3 <- #112
DO ,1 SUB #4 <- #112
DO ,1 SUB #5 <- #208
DO ,1 SUB #6 <- #64
DO ,1 SUB #7 <- #16
PLEASE DO ,1 SUB #8 <- #162
DO ,1 SUB #9 <- #214
DO ,1 SUB #10 <- #56
DO ,1 SUB #11 <- #242
PLEASE DO ,1 SUB #12 <- #178
DO ,1 SUB #13 <- #48
DO ,1 SUB #14 <- #44
DO ,1 SUB #15 <- #0
DO ,1 SUB #16 <- #208
DO ,1 SUB #17 <- #240
DO ,1 SUB #18 <- #144
DO ,1 SUB #19 <- #34
PLEASE READ OUT ,1
PLEASE GIVE UP
`
  },
  nemerle: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Nemerle Compiler, Editor, and IDE for seamless Nemerle coding online. Compile, run, share, and save your Nemerle programs easily. Try JDoodle's Online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online Nemerle Compiler - Online Nemerle Editor - Online Nemerle IDE - Nemerle Coding Online - Online Nemerle Runner -  Share Save Nemerle online'
      }
    ],
    title: 'Online Nemerle Compiler',
    header: 'Nemerle Code',
    fileName: 'n',
    canonicalPath: 'compile-nemerle-online',
    language: 'nemerle',
    versions: ['1.2.0.507'],
    langDisplayName: 'Nemerle',
    isCompile: true,
    sampleScript: `class JDoodle
{
    static Main () : void
    {
        def x = 10;
        def y = 25;
        def z = x + y;
        System.Console.WriteLine ("Sum of {0} and {1} is {2}", x, y, z);
    }
}`
  },
  ocaml: {
    metaTags: [
      {
        name: 'description',
        content:
          'Experience the best OCaml online compiler and editor with JDoodle. Write, compile, and execute OCaml code directly from your browser effortlessly.'
      },
      {
        name: 'keywords',
        content:
          'Online Ocaml Compiler - Online Ocaml Editor - Online Ocaml IDE - Ocaml Coding Online - Online Ocaml Runner -  Share Save Ocaml online'
      }
    ],
    title: 'OCaml Online Compiler',
    header: 'Ocaml Code',
    fileName: 'ml',
    canonicalPath: 'compile-ocaml-online',
    language: 'ocaml',
    versions: ['4.03.0', '4.08.1', '4.12.0', '5.0.0'],
    langDisplayName: 'Ocaml',
    isCompile: true,
    sampleScript: `print_string "Welcome to OCAML online ide from JDoodle.com!\\n";;`
  },

  unlambda: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's Unlambda Online IDE and Compiler for efficient Unlambda coding. Write, compile, and execute Unlambda code seamlessly from your browser."
      },
      {
        name: 'keywords',
        content:
          'Unlambda Online Editor - Unlambda Online IDE -  Unlambda Coding Online - Share Save Unlambda Program online'
      }
    ],
    title: 'Unlambda Online IDE',
    header: 'Unlambda Code',
    fileName: 'un',
    canonicalPath: 'execute-unlambda-online',
    language: 'unlambda',
    versions: ['0.1.3', '0.1.4.2'],
    langDisplayName: 'Unlambda',
    isCompile: false,
    sampleScript: '`r``````````````````.W.e.l.c.o.m.e. .t.o. .J.D.o.o.d.l.ei'
  },

  picolisp: {
    metaTags: [
      {
        name: 'description',
        content:
          "Try JDoodle's free online PicoLisp editor and compiler. Write, run, and test PicoLisp code in your browser instantly. No installation needed."
      },
      {
        name: 'keywords',
        content:
          'Picolisp Online Editor - Picolisp Online IDE -  Picolisp Coding Online - Share Save Picolisp Program online'
      }
    ],
    title: 'Picolisp Online Editor',
    header: 'Picolisp Code',
    fileName: 'pil',
    canonicalPath: 'execute-picolisp-online',
    language: 'picolisp',
    versions: ['3.1.11.1', '17.11.14', '18.5.11', '18.9.5', '21.6.30', '23.9.29'],
    langDisplayName: 'Picolisp',
    aceCode: 'lisp',
    isCompile: false,
    sampleScript: `"Welcome to Picolisp online ide from JDoodle.com"
(bye)`
  },

  spidermonkey: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's free SpiderMonkey online editor and compiler. Write, run, and debug JavaScript code in your browser. No setup required."
      },
      {
        name: 'keywords',
        content:
          'SpiderMonkey Online Editor - SpiderMonkey Online IDE -  SpiderMonkey Coding Online - Share Save SpiderMonkey Program online'
      }
    ],
    title: 'SpiderMonkey Online Editor',
    header: 'SpiderMonkey Code',
    fileName: 'js',
    canonicalPath: 'execute-spidermonkey-online',
    language: 'spidermonkey',
    versions: ['38', '45.0.2', '91'],
    langDisplayName: 'SpiderMonkey',
    isCompile: false,
    sampleScript: `var x = 10;
var y = 25;
var z = x + y;

console.log("x + y = " + z);`
  },

  rhino: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's free online JavaScript (Rhino) compiler and IDE. Write, run, and debug Rhino JS code in your browser. No installation needed."
      },
      {
        name: 'keywords',
        content:
          'Rhino JS Online Editor - Rhino JS Online IDE -  Rhino JS Coding Online - Share Save Rhino JS Program online'
      }
    ],
    title: 'Online Javascript (Rhino) Compiler and IDE',
    header: 'Rhino JS Code',
    fileName: 'js',
    canonicalPath: 'execute-rhino-online',
    language: 'rhino',
    versions: ['1.7.7.1', '1.7.7.2', '1.7.13', '1.7.14'],
    langDisplayName: 'Rhino JS',
    isCompile: false,
    sampleScript: `var x = 10;
var y = 25;
var z = x + y;

print('Sum of ',x,' and ',y,' is ',z);`
  },
  bc: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's free online BC (Basic Calculator) editor. Write and execute BC scripts in your browser instantly. No setup needed."
      },
      {
        name: 'keywords',
        content:
          'BC Online Editor - BC Online IDE -  BC Coding Online - Share Save BC Program online'
      }
    ],
    title: 'BC Online Editor',
    header: 'BC Code',
    fileName: 'bc',
    canonicalPath: 'execute-bc-online',
    language: 'bc',
    versions: ['1.06.95', '1.07.1'],
    langDisplayName: 'BC',
    isCompile: false,
    sampleScript: `x = 10;
y = 25;
z = x + y;

print "Sum of ",x," and ",y," is ",z;
quit;`
  },
  clisp: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's free CLISP and CommonLisp online editor and compiler. Write, run, and debug Lisp code in your browser. No setup required."
      },
      {
        name: 'keywords',
        content:
          'CLISP Online Editor - CLISP Online IDE -  CLISP Coding Online - Share Save CLISP Program online'
      }
    ],
    title: 'CLISP Online Editor',
    header: 'CLISP Code',
    fileName: 'lisp',
    canonicalPath: 'execute-clisp-online',
    language: 'clisp',
    defaultVersion: 3,
    versions: [
      'GNU CLISP 2.49 - GNU C 5.2.0 ',
      'GNU CLISP 2.49 - GNU C 6.2.1',
      'GNU CLISP 2.49.93 - GNU 8.1.0',
      'GNU CLISP 2.49.93 - GNU 9.1.0',
      'ecl 21.2.1',
      'sbcl 2.1.9',
      'ccl 1.12.1',
      'abcl 1.8.0',
      'ecl 23.9.9',
      'sbcl 2.3.10',
      'ccl 1.12.2',
      'abcl 1.9.2'
    ],
    langDisplayName: 'CLISP',
    aceCode: 'lisp',
    isCompile: false,
    sampleScript: `(print  "welcome to CLISP online ide from JDoodle.com")`
  },

  elixir: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's free Elixir online compiler and editor. Write, run, and test Elixir code in your browser instantly. No installation needed."
      },
      {
        name: 'keywords',
        content:
          'Elixir Online Editor - Elixir Online IDE -  Elixir Coding Online - Share Save Elixir Program online'
      }
    ],
    title: 'Elixir Online Compiler',
    header: 'Elixir Code',
    fileName: 'exs',
    canonicalPath: 'execute-elixir-online',
    language: 'elixir',
    versions: ['1.3.4', '1.5.2', '1.6.4', '1.9.1', '1.12.2', '1.15.7'],
    langDisplayName: 'Elixir',
    isCompile: false,
    sampleScript: `x = 10
y = 25
z = x + y

IO.puts "Sum of #{x} and #{y} is #{z}"`
  },

  factor: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Factor Compiler. JDoodle's free online Factor compiler and IDE. Write, run, and debug Factor code in your browser instantly. No setup required."
      },
      {
        name: 'keywords',
        content:
          'Factor Online Editor - Factor Online IDE -  Factor Coding Online - Share Save Factor Program online'
      }
    ],
    title: 'Online Factor Compiler',
    header: 'Factor Code',
    fileName: 'factor',
    canonicalPath: 'execute-factor-online',
    language: 'factor',
    versions: ['8.25', '8.28', '8.29', '8.31', '9.4'],
    langDisplayName: 'Factor',
    isCompile: false,
    sampleScript: `USE: io

"Hello, Welcome to Factor online ide from JDoodle.com" print`
  },

  falcon: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Falcon Compiler. JDoodle's Falcon online editor and compiler. Write, run, and test Falcon code in your browser instantly. No installation needed."
      },
      {
        name: 'keywords',
        content:
          'Falcon Online Editor - Falcon Online IDE -  Falcon Coding Online - Share Save Falcon Program online'
      }
    ],
    title: 'Falcon Online Editor',
    header: 'Falcon Code',
    fileName: 'fl',
    canonicalPath: 'execute-falcon-online',
    language: 'falcon',
    versions: ['0.9.6.8 (Chimera)'],
    langDisplayName: 'Falcon',
    isCompile: false,
    sampleScript: `> x = 10
> y = 25
> z = x + y
> "Sum of ",x ," and ", y," is ", z`
  },

  fantom: {
    metaTags: [
      {
        name: 'description',
        content:
          'Utilize our Online Fantom Compiler and Editor for seamless Fantom coding. Write, compile, and execute Fantom code directly from your browser efficiently.'
      },
      {
        name: 'keywords',
        content:
          'Fantom Online Editor - Fantom Online IDE -  Fantom Coding Online - Share Save Fantom Program online'
      }
    ],
    title: 'Online Fantom Compiler',
    header: 'Fantom Code',
    fileName: 'fan',
    canonicalPath: 'execute-fantom-online',
    language: 'fantom',
    versions: ['1.0.69'],
    langDisplayName: 'Fantom',
    isCompile: false,
    sampleScript: `class HelloWorld
{
    static Void main()
    {
        x := 10
        y := 25
        z := x + y
        echo("Sum of " + x + " and " + y + " is " + z)
    }
}`
  },

  nim: {
    metaTags: [
      {
        name: 'description',
        content:
          'Write and compile Nim code effortlessly with our Nim Online Editor. Enjoy a fast, efficient Nim online compiler for a seamless and productive coding experience.'
      },
      {
        name: 'keywords',
        content:
          'Nim Online Editor - Nim Online IDE -  Nim Coding Online - Share Save Nim Program online'
      }
    ],
    title: 'Nim Online Editor',
    header: 'Nim Code',
    fileName: 'nim',
    canonicalPath: 'execute-nim-online',
    language: 'nim',
    versions: ['0.15.0', '0.17.2', '0.18.0', '1.4.8', '2.0.0'],
    langDisplayName: 'Nim',
    isCompile: false,
    sampleScript: `var x,y,z :int
x = 10
y = 25
z = x + y
echo ("sum of ", x, " and ", y, " is ", z)
`
  },

  pike: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Pike Compiler to write and compile Pike code instantly. Experience a fast, efficient coding environment for seamless Pike programming and development.'
      },
      {
        name: 'keywords',
        content:
          'Pike Online Editor - Pike Online IDE -  Pike Coding Online - Share Save Pike Program online'
      }
    ],
    title: 'Online Pike Compiler',
    header: 'Pike Code',
    fileName: 'pike',
    canonicalPath: 'execute-pike-online',
    language: 'pike',
    versions: ['v8.0', 'v8.0.702'],
    langDisplayName: 'Pike',
    isCompile: false,
    sampleScript: `int main()
{
    int x = 10;
    int y = 25;
    int z = x + y;
    write("Sum of " + x +" and " + y + " is " + z);
    return 0;
}`
  },
  smalltalk: {
    metaTags: [
      {
        name: 'description',
        content:
          'SmallTalk Online Editor for writing and compiling SmallTalk code instantly. Enjoy a fast, efficient online SmallTalk compiler for a seamless coding experience.'
      },
      {
        name: 'keywords',
        content:
          'SmallTalk Online Editor - SmallTalk Online IDE -  SmallTalk Coding Online - Share Save SmallTalk Program online'
      }
    ],
    title: 'SmallTalk Online Editor',
    header: 'SmallTalk Code',
    fileName: 'gst',
    canonicalPath: 'execute-smalltalk-online',
    language: 'smalltalk',
    versions: ['GNU SmallTalk 3.2.92'],
    langDisplayName: 'SmallTalk',
    isCompile: false,
    sampleScript: "Transcript show: 'Welcome to SmallTalk online ide from JDoodle.com'."
  },

  mozart: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online OZ-Mozart Compiler for writing and compiling OZ-Mozart code effortlessly. Experience a fast, efficient coding environment for seamless OZ-Mozart programming.'
      },
      {
        name: 'keywords',
        content:
          'Online OZ-Mozart Compiler - Online OZ-Mozart Editor - Online OZ-Mozart IDE - OZ-Mozart Coding Online - Online OZ-Mozart Runner -  Share Save OZ-Mozart online'
      }
    ],
    title: 'Online OZ-Mozart Compiler',
    header: 'OZ-Mozart Code',
    fileName: 'oza',
    canonicalPath: 'compile-oz-mozart-online',
    language: 'mozart',
    versions: ['2.0.0 (OZ 3)'],
    langDisplayName: 'OZ Mozart',
    isCompile: true,
    sampleScript: `functor
import
    System
    Application
define
    {System.showInfo 'Welcome to JDoodle!'}
    {Application.exit 0}
end`
  },

  lolcode: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online LOLCODE Compiler and Editor for writing and compiling LOLCODE programming language effortlessly. Enjoy a fast, efficient coding experience with our LOLCODE online tools.'
      },
      {
        name: 'keywords',
        content:
          'LOLCODE Online Editor - LOLCODE Online IDE -  LOLCODE Coding Online - Share Save LOLCODE Program online'
      }
    ],
    title: 'Online LOLCODE Compiler',
    header: 'LOLCODE Code',
    fileName: 'lo',
    canonicalPath: 'execute-lolcode-online',
    language: 'lolcode',
    versions: ['0.10.5'],
    langDisplayName: 'LOLCODE',
    isCompile: false,
    sampleScript: `HAI 1.2
VISIBLE "Welcome to JDoodle!!"
KTHXBYE`
  },

  racket: {
    metaTags: [
      {
        name: 'description',
        content:
          "Discover JDoodle's Racket Online Compiler and Editor for instant coding and compiling. Experience efficient Racket programming with our online tools."
      },
      {
        name: 'keywords',
        content:
          'Racket Online Editor - Racket Online IDE -  Racket Coding Online - Share Save Racket Program online'
      }
    ],
    title: 'Racket Online Compiler',
    header: 'Racket Code',
    fileName: 'rkt',
    canonicalPath: 'execute-racket-online',
    language: 'racket',
    versions: ['6.11', '6.12', '7.4', '8.3'],
    langDisplayName: 'Racket',
    isCompile: false,
    sampleScript: `(writeln "Welcome to JDoodle!!")`
  },

  kotlin: {
    metaTags: [
      {
        name: 'description',
        content:
          "Kotlin Online Compiler - Write, execute and share Kotlin code with JDoodle's Kotlin Compiler directly from your browser."
      },
      {
        name: 'advanceDescription',
        content:
          "Kotlin Online Compiler - Write, execute and share Kotlin code with Kotlin Compiler on JDoodle's Advanced IDE. Execute code directly from your browser."
      },
      {
        name: 'keywords',
        content:
          'Online Kotlin Compiler - Online Kotlin Editor - Online Kotlin IDE - Kotlin Coding Online - Online Kotlin Runner -  Share Save Kotlin online'
      }
    ],
    title: 'Kotlin Online Compiler',
    advanceTitle: 'Kotlin Online Compiler - Advanced IDE',
    header: 'Kotlin Code',
    fileName: 'kt',
    canonicalPath: 'compile-kotlin-online',
    language: 'kotlin',
    versions: [
      '1.1.51 (JRE 9.0.1+11)',
      '1.2.40 (JRE 10.0.1)',
      '1.3.50 (JRE 11.0.4)',
      '1.6.0 (JRE 17.0.1+12)',
      '1.9.10 (JRE 17.0.1+12)'
    ],
    langDisplayName: 'Kotlin',
    isCompile: true,
    isPlus: true,
    plusVersions: ['1.9.10 (JRE 17.0.1+12)'],
    library: LIBRARY.maven,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.kt'],
    sampleScript: `@file:JvmName("JDoodle")
fun main(args: Array<String>) {
  val x: Int = 10
  val y: Int = 25
  val z = x + y
  println("The sum of $x + $y is  $z")
}`
  },

  whitespace: {
    metaTags: [
      {
        name: 'description',
        content:
          'Explore an Online Whitespace Compiler and Editor for effortless coding and compiling. Enjoy a streamlined whitespace programming experience with our online tools.'
      },
      {
        name: 'keywords',
        content:
          'Whitespace Online Editor - Whitespace Online IDE -  Whitespace Coding Online - Share Save Whitespace Program online'
      }
    ],
    title: 'Online Whitespace Compiler',
    header: 'Whitespace Code',
    fileName: 'ws',
    canonicalPath: 'execute-whitespace-online',
    language: 'whitespace',
    versions: ['0.3'],
    langDisplayName: 'Whitespace',
    isCompile: false,
    sampleScript:
      // eslint-disable-next-line no-tabs
      `Welcome to JDoodle.com   \n   	  	   \n		    	\n   		  	 	\n		    	 \n   		 		  \n		    		\n   		 		  \n		    	  \n   		 				\n		    	 	\n   	 		  \n		    		 \n   	     \n		    			\n   			 			\n		    	   \n   		 				\n		    	  	\n   			  	 \n		    	 	 \n   		 		  \n		    	 		\n   		  	  \n		    		  \n   	     \n		    		 	\n   		 				\n		    			 \n   		  		 \n		    				\n   	     \n		    	    \n   			  		\n		    	   	\n   			    \n		    	  	 \n   		    	\n		    	  		\n   		   		\n		    	 	  \n   		  	 	\n		    	 	 	\n   			  		\n		    	 		 \n   	    	\n		    	 			\n    \n		     \n\n 	 			 			 			  	  		 	  	 			 	   		  	 	\n\n 	 		 			  		  	 	 			 			 		 		   		 	  	 		 			  		  	 	\n\n\n\n\n   		    	 		  	   		  	  \n	   \n	\n\n   			 			 			  	  		 	  	 			 	   		  	 	\n \n 			 \n \n	  			 			 			  	  		 	  	 			 	   		  	 	 	 					 		  	 	 		 			  		  	  \n	\n     	\n	   \n \n 			 			 			  	  		 	  	 			 	   		  	 	\n\n   			 			 			  	  		 	  	 			 	   		  	 	 	 					 		  	 	 		 			  		  	  \n \n\n \n\n\n	\n\n   			  	  		  	 	 		    	 		  	  \n \n  \n 	\n	 			 \n    	 	 \n	  	\n	  			  	  		  	 	 		    	 		  	   	 					 		  	 	 		 			  		  	  \n \n\n   	\n	   \n \n 			  	  		  	 	 		    	 		  	  \n\n   			  	  		  	 	 		    	 		  	   	 					 		  	 	 		 			  		  	  \n \n\n   	\n	       \n		 \n	\n\n   		 			  		  	 	 			 			 		 		   		 	  	 		 			  		  	 	\n   	 	 \n   		 	\n	\n  	\n  \n	\n`
  },
  erlang: {
    metaTags: [
      {
        name: 'description',
        content:
          'Learn about the Erlang programming language and use our Erlang Online Compiler for efficient code compilation. Start coding Erlang online today.'
      },
      {
        name: 'keywords',
        content:
          'Online J Language Compiler - Online Erlang Editor - Online Erlang IDE - Erlang Coding Online - Online Erlang Runner -  Share Save Erlang online'
      }
    ],
    title: 'Erlang Online Compiler',
    header: 'Erlang Code',
    fileName: 'erl',
    canonicalPath: 'execute-erlang-online',
    language: 'erlang',
    versions: ['22.1', '24', '26.1.1'],
    langDisplayName: 'Erlang',
    isCompile: false,
    sampleScript: `io:format("Welcome to JDoodle!~n").`
  },
  jlang: {
    metaTags: [
      {
        name: 'description',
        content:
          "Explore JDoodle's Online J Language Compiler and IDE for efficient coding and compilation. Start coding in J language online with our online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online J Language Compiler - Online J Language Editor - Online J Language IDE - J Language Coding Online - Online J Language Runner -  Share Save J Language online'
      }
    ],
    title: 'Online J Language Compiler',
    header: 'J Language Code',
    fileName: 'jl',
    canonicalPath: 'execute-jlanguage-online',
    language: 'jlang',
    versions: ['9.01.10'],
    langDisplayName: 'J Language',
    isCompile: false,
    sampleScript: `echo 'Welcome to JDoodle.com!'
exit ''`
  },
  jbang: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's Online JBang Compiler and IDE for coding with JBang scripts. Experience fast compilation and an intuitive JBang development environment online."
      },
      {
        name: 'keywords',
        content:
          'Online JBang Compiler - Online JBang Editor - Online JBang IDE - JBang Coding Online - Online JBang Runner -  Share Save JBang online'
      }
    ],
    title: 'Online JBang Compiler',
    header: 'JBang Code',
    fileName: 'java',
    canonicalPath: 'try-jbang',
    language: 'jbang',
    versions: ['0.83.1'],
    langDisplayName: 'JBang',
    isCompile: false,
    aceCode: 'java',
    sampleScript: `///usr/bin/env jbang "$0" "$@" ; exit $?
//DEPS com.github.lalyos:jfiglet:0.0.8

import com.github.lalyos.jfiglet.FigletFont;

class hello {

    public static void main(String... args) throws Exception {
        System.out.println(FigletFont.convertOneLine(
               "Hello " + ((args.length>0)?args[0]:"jbang")));  ;;
    }
}`
  },
  fasm: {
    metaTags: [
      {
        name: 'description',
        content:
          'Compile FASM Assembly code online with our fast and efficient Online FASM Assembly Compiler. Start coding in Assembly language instantly.'
      },
      {
        name: 'keywords',
        content:
          'Online FASM Assembly Language Compiler - Online FASM Assembly Editor - Online FASM Assembly IDE - FASM Assembly Coding Online - Online FASM Assembly Runner -  Share Save FASM Assembly online'
      }
    ],
    title: 'Online FASM Assembly Compiler',
    header: 'FASM Code',
    fileName: 'asm',
    canonicalPath: 'execute-fasm-online',
    language: 'fasm',
    versions: ['1.73.27', '1.73.31'],
    langDisplayName: 'FASM',
    aceCode: 'assembly_x86',
    isCompile: true,
    sampleScript: `format ELF64 executable 3

segment readable executable

entry main

main:
    lea  rdi, [msg]                               ; address of msg goes into rdi
    mov  rax, 14                                  ; put length of msg into rax
    mov  rdx, rax                                 ; move rax to rdx
    mov  rsi, rdi                                 ; move rdi to rsi
    mov  rdi, 1                                   ; stdout
    mov  rax, 1                                   ; sys_write
    syscall
    xor  rdi, rdi                                 ; exit code 0
    mov  rax, 60                                  ; sys_exit
    syscall

segment readable writable

msg  db 'Hello world!', 10, 0
`
  },
  awk: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write AWK code and run it online with JDoodle's Online AWK Compiler, Editor. JDoodle's AI powered online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online AWK Language Compiler - Online AWK Editor - Online AWK IDE - AWK Coding Online - Online AWK Runner -  Share Save AWK online'
      }
    ],
    title: 'Online AWK Compiler',
    header: 'AWK Code',
    fileName: 'awk',
    canonicalPath: 'execute-awk-online',
    language: 'awk',
    versions: ['GNU Awk 5.1.1, API: 3.1', 'API: 5.3'],
    langDisplayName: 'AWK',
    aceCode: 'empty',
    isCompile: false,
    sampleScript: `BEGIN {
   print "Welcome to JDoodle!"
}`
  },
  algol: {
    metaTags: [
      {
        name: 'description',
        content:
          "Compile Algol 68 Genie code online with our efficient Algol 68 Genie Compiler. Start coding using JDoodle's online ALGOL Compiler."
      },
      {
        name: 'keywords',
        content:
          'Online Algol 68 Language Compiler - Online Algol 68 Genie Editor - Online Algol 68 Genie IDE - Algol 68 Genie Coding Online - Online Algol 68 Genie Runner -  Share Save Algol 68 Genie online'
      }
    ],
    title: 'Online Algol 68 Genie Compiler',
    header: 'Algol 68 Code',
    fileName: 'alg',
    canonicalPath: 'execute-algol68-online',
    language: 'algol',
    aceCode: 'empty',
    versions: ['Genie 2.8.5', 'Genie 3.4.2'],
    langDisplayName: 'Algol 68',
    isCompile: false,
    sampleScript: `print("Welcome to JDoodle!")`
  },
  befunge: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Befunge code and run it online with JDoodle's Befunge Compiler, Editor. JDoodle's AI powered online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online Befunge Language Compiler - Online Befunge Editor - Online Befunge IDE - Befunge Coding Online - Online Befunge Runner -  Share Save Befunge online'
      }
    ],
    title: 'Online Befunge Compiler',
    header: 'Befunge Code',
    fileName: 'be',
    canonicalPath: 'execute-befunge-online',
    language: 'befunge',
    aceCode: 'empty',
    versions: ['cfunge 0.9.0', 'cfunge 1.1.001'],
    langDisplayName: 'Befunge',
    isCompile: false,
    sampleScript: `0"!eldooDJ ot emocleW">:#,_@`
  },
  haxe: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Haxe code and run it with JDoodle's Online Haxe Compiler, Editor. JDoodle's AI powered online IDE supporting 88+ languages."
      },
      {
        name: 'keywords',
        content:
          'Online Haxe Compiler - Online Haxe Editor - Online Haxe IDE - Haxe Coding Online - Online Haxe Runner -  Share Save Haxe online'
      }
    ],
    title: 'Online Haxe Compiler',
    header: 'Haxe Code',
    fileName: 'hx',
    canonicalPath: 'execute-haxe-online',
    language: 'haxe',
    versions: ['4.2.4', '4.3.1'],
    langDisplayName: 'Haxe',
    isCompile: true,
    sampleScript: `class Main {
  static public function main():Void {
    trace("Welcome to JDoodle!");
  }
}`
  },
  moonscript: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDoodle's MoonScript online compiler lets you compile and execute code interactively in your browser."
      },
      {
        name: 'keywords',
        content:
          'Moonscript Online Editor - Moonscript Online IDE -  Moonscript Coding Online - Share Save Moonscript Program online'
      }
    ],
    title: 'MoonScript Online Compiler',
    header: 'Moonscript Code',
    fileName: 'moon',
    canonicalPath: 'execute-moonscript-online',
    language: 'moonscript',
    aceCode: 'empty',
    versions: ['0.5.0'],
    langDisplayName: 'Moonscript',
    isCompile: true,
    sampleScript: `x = 10
y = 25
z = x + y

print "Sum of x+y = #{z}"`
  },
  tasm: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Assembler code and run it online with JDoodle's Assembler online compiler. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Online Assembler (Turbo) Compiler - Online Assembler (Turbo) Editor - Online Assembler (Turbo) IDE - Assembler (Turbo) Coding Online - Online Assembler (Turbo) Runner -  Share Save Assembler (Turbo) online'
      }
    ],
    title: 'Online Assembler Compiler',
    header: 'Assembler (Turbo) Code',
    fileName: 'asm',
    canonicalPath: 'compile-assembler-tasm-online',
    language: 'tasm',
    versions: ['1.59.3120'],
    langDisplayName: 'Assembler - Turbo',
    aceCode: 'assembly_x86',
    isCompile: false,
    sampleScript: `    * = $0801            ; Program start address for C64
    LDX #$00             ; Set X register to 0
print_loop:
    LDA message,X       ; Load the character at message + X into A
    BEQ done            ; If the character is 0, we're done
    JSR $FFD2           ; Output the character in A to the screen (C64 routine)
    INX                 ; Increment X
    JMP print_loop      ; Jump back to the start of the loop
done:
    RTS                 ; Return from subroutine
message:
    .text "Welcome to Jdoodle Turbo Assembler!"
    .byte 0             ; Null-terminator

`
  },
  deno: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Deno code and run it online with JDoodle's Online Deno Editor. JDoodle's AI powered online IDE"
      },
      {
        name: 'keywords',
        content:
          'Deno Online Editor - Deno Online IDE -  Deno Coding Online - Share Save Deno Program online'
      }
    ],
    title: 'Online Deno Editor',
    header: 'Deno Code',
    fileName: 'ts',
    canonicalPath: 'execute-deno-online',
    language: 'deno',
    aceCode: 'empty',
    versions: ['1.38.0'],
    langDisplayName: 'Deno',
    isCompile: false,
    sampleScript: `const x: number = 10;
const y: number = 25;
const z: number = x + y;

console.log("Sum of x + y = " + z);
`
  },
  jelly: {
    metaTags: [
      {
        name: 'description',
        content:
          'Write and run Jelly code using our Jelly Coding Online IDE. Write, execute & share in Jelly code directly from your browser.'
      },
      {
        name: 'keywords',
        content:
          'Jelly Online Editor - Jelly Online IDE -  Jelly Coding Online - Share Save Jelly Program online'
      }
    ],
    title: 'Jelly Coding Online IDE',
    header: 'Jelly Code',
    fileName: 'jelly',
    canonicalPath: 'execute-jelly-online',
    language: 'jelly',
    aceCode: 'empty',
    versions: ['1.0'],
    langDisplayName: 'Jelly',
    isCompile: false,
    sampleScript: `“3ḅaė;œ»`
  },
  cow: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Cow Compiler. Code, Compile and Execute COW programming language on JDoodle's Online IDE"
      },
      {
        name: 'keywords',
        content:
          'Online Cow Compiler - Online Cow Editor - Online Cow IDE - Cow Coding Online - Online Cow Runner -  Share Save Cow online'
      }
    ],
    title: 'Online Cow Compiler',
    header: 'Cow Code',
    fileName: 'cow',
    canonicalPath: 'execute-cow-online',
    language: 'cow',
    aceCode: 'empty',
    versions: ['1.0'],
    langDisplayName: 'Cow',
    isCompile: false,
    sampleScript: `OOOMoOMoOMoOMoOMoOMoOMoOMoOMMMmoOMMMMMMmoOMMMMOOMOomOoMoOmoOmoomOo
MMMmoOMMMMMMmoOMMMMOOMOomOoMoOmoOmoomOoMMMmoOMMMMMMmoOMMMMOOMOomOo
MoOmoOmooOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOomOoMMMmoO
moOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOO
mOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOo
moomoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOo
moomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoO
MoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOo
MMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMM
moOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoO
MoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMM
moOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoO
MoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMM
MOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoO
MoOMoOMoomOoOOOmoOOOOmOomOomOomOoMMMmoOmoOmoOMMMMOOMOomoOMoOmOomoo
moOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOO
mOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOo
moomOomOomOoMMMmoOmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoomOo
OOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOo
moOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOo
OOOmoOOOOmOomOomOomOoMMMmoOmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoO
MoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoO
MMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoO
OOOmOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoO
mOomoomoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOo
moomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoO
MoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOo
moomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoOMoOMoOMoO
MoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOoMMMmoOMMMMOOMOomoOMoOmOo
moomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoOMoOMoOMoOMoOMoomOoOOOmoO
OOOmOomOoMMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoO
mOomoomoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOo
MMMmoOMMMMOOMOomoOMoOmOomoomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOomoomoO
MoOMoOMoOMoOMoOMoomOoOOOmoOOOOmOomOomOoMMMmoOmoOMMMMOOMOomoOMoOmOo
moomoOMoOMoomOo`
  },
  apl: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write APL programming language and run it with JDoodle's APL online compiler. JDoodle's AI powered online IDE supporting 88+ languages."
      },
      {
        name: 'keywords',
        content:
          'Online Apl Compiler - Online Apl Editor - Online Apl IDE - Apl Coding Online - Online Apl Runner -  Share Save Apl online'
      }
    ],
    title: 'APL Online Compiler',
    header: 'Apl Code',
    fileName: 'apl',
    canonicalPath: 'compile-apl-online',
    language: 'apl',
    aceCode: 'empty',
    versions: ['1.8'],
    langDisplayName: 'Apl',
    isCompile: false,
    sampleScript: `8 ⎕CR 1 2 3 (4 5)`
  },
  crystal: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Crystal Compiler - Online Crystal Editor - Online Crystal IDE -  Crystal Coding Online - Online Crystal Runner - Share Save Crystal Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Crystal Compiler - Online Crystal Editor - Online Crystal IDE - Crystal Coding Online - Online Crystal Runner -  Share Save Crystal online'
      }
    ],
    title:
      'Online Crystal Compiler - Online Crystal Editor -  Run Crystal Online - Online Crystal Runner',
    header: 'Crystal Code',
    fileName: 'cr',
    canonicalPath: 'online-compiler-crystal',
    versions: ['1.10.1'],
    language: 'crystal',
    langDisplayName: 'Crystal',
    isCompile: true,
    sampleScript: `x = 10
y = 25
z = x + y

puts "Sum of x+y = #{z}"`
  },
  typescript: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write, execute & share TypeScript code online with JDoodle's Online TypeScript Compiler. Easily run code snippets with JDoodle's online coding platform."
      },
      {
        name: 'advanceDescription',
        content:
          "Write, execute & share TypeScript code online with JDoodle's Online TypeScript Compiler. Gain access to multifile support features with our Advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'Online Typescript Compiler - Online Typescript Editor - Online Typescript IDE - Typescript Coding Online - Online Typescript Runner -  Share Save Typescript online'
      }
    ],
    title: 'Online TypeScript Compiler',
    advanceTitle: 'Online TypeScript Compiler - Advanced IDE',
    header: 'Typescript Code',
    fileName: 'ts',
    canonicalPath: 'online-compiler-typescript',
    versions: ['5.2.2'],
    language: 'typescript',
    langDisplayName: 'Typescript',
    isCompile: true,
    isPlus: true,
    plusVersions: ['5.2.2'],
    library: LIBRARY.typenpm,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.ts', '.json'],
    sampleScript: `const x: number = 10;
const y: number = 25;
const z: number = x + y;

console.log("Sum of x+ y = " + z);`
  },
  raku: {
    metaTags: [
      {
        name: 'description',
        content:
          'Raku Online Editor - Raku Online IDE -  Raku Coding Online - Share Save Raku Program online'
      },
      {
        name: 'keywords',
        content:
          'Raku Online Editor - Raku Online IDE -  Raku Coding Online - Share Save Raku Program online'
      }
    ],
    title: 'Raku Online Editor - Raku Online IDE -  Raku Coding Online',
    header: 'Raku Code',
    fileName: 'raku',
    canonicalPath: 'execute-raku-online',
    language: 'raku',
    versions: ['2023.1'],
    langDisplayName: 'Raku',
    isCompile: true,
    sampleScript: `my $x = 10;
my $y = 25;
my $z = $x + $y;

say "Sum of x+y = $z";`
  },
  julia: {
    metaTags: [
      {
        name: 'description',
        content:
          "Online Julia Compiler - Write, execute and share Julia code with JDoodle's Online Julia Compiler directly from your browser."
      },
      {
        name: 'keywords',
        content:
          'Online Julia Compiler - Online Julia Editor - Online Julia IDE - Julia Coding Online - Online Julia Runner -  Share Save Julia online'
      }
    ],
    title: 'Online Julia Compiler',
    header: 'Julia Code',
    fileName: 'jl',
    canonicalPath: 'online-compiler-julia',
    versions: ['1.9.3'],
    language: 'julia',
    langDisplayName: 'Julia',
    isCompile: false,
    sampleScript: `x = 10
y = 25
z = x + y

println("Sum of x+y = ", z)`
  },
  blockly: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write Blockly code and run it with JDoodle's Online Blockly IDE, Editor. JDoodle's AI powered online IDE supporting 88+ languages."
      },
      {
        name: 'keywords',
        content: 'Blockly Online'
      }
    ],
    title: 'Blockly Online | Blockly IDE',
    header: 'Blockly',
    fileName: 'hx',
    canonicalPath: 'blockly-online',
    language: 'blockly',
    versions: ['Dart', 'Lua', 'PHP', 'Python'],
    blocklyAceCodes: ['dart', 'lua', 'PHP', 'python'],
    blocklyLanguages: ['dart', 'lua', 'php', 'python3'],
    blocklyLanguageVersions: [4, 3, 4, 4],
    langDisplayName: 'Blockly',
    isCompile: false,
    sampleScript: `{"blocks":{"languageVersion":0,"blocks":[{"type":"text_print","id":"%B3I#U(F2j{@ZQ;Kc5r^","x":16,"y":8,"inputs":{"TEXT":{"block":{"type":"text","id":"LM9uh/dp#q2d^z=Kfq{#","fields":{"TEXT":"Welcome to JDoodle"}}}}}]}}`,
    aceCode: 'python'
  },
  html: {
    metaTags: [
      {
        name: 'description',
        content:
          "Write and run HTML, CSS, JS code using our HTML, CSS, JS online compiler. You can write, execute & share with JDoodle's online IDE"
      },
      {
        name: 'advanceDescription',
        content:
          "Write and run HTML, CSS, JS code using our HTML, CSS, JS online compiler. Write, execute & share with JDoodle's online advanced IDE"
      },
      {
        name: 'keywords',
        content:
          'Online HTML/CSS/Javascript Editor, test html css javascript online, online ide for html css javascript - save share html css java script online'
      }
    ],
    title: 'Online HTML CSS Javascript Editor',
    advanceTitle: 'Online HTML CSS Javascript Editor - Advanced IDE',
    header: 'HTML/CSS/JS',
    fileName: '.html',
    canonicalPath: 'html-css-javascript-online-editor',
    language: 'html',
    langDisplayName: 'HTML/CSS/JS',
    isCompile: false,
    isPlus: true,
    editableTypes: ['.txt', '.csv', '.json', '.xml', '.yml', '.data', '.html', '.css', '.js'],
    sampleScript: import.meta.env.SSR
      ? ''
      : `<html>
<head>
  <style>
    .welcome {
      height:100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .welcome h1 {
      font-size:20px;
      margin: 0px;
      margin-bottom:20px;
    }

    .divider {
      width: 1px;
      height: 25px; 
      background-color: #5F7394;
      margin: 0 10px; 
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      margin: 10px 0px;
    }

    .links a {
      color: #5F7394; 
      text-decoration: underline; 
      margin: 0 10px; 
    }

    .counter-container {
      margin-top: 20px;
    }

    .counter-button {
      font-size: 18px;
      padding: 10px 20px;
      background-color: #5F7394;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .counter-button:hover {
      background-color: #2A4D77;
    }

    .counter-display {
      font-size: 18px;
      margin-top: 10px;
    }
  </style>
</head>
<body>
  <div class="welcome">
    <img id="logo"  style="height: 150px; width:450px" />
    <div>
      <h1>Your friendly cloud-based coding platform.</h1>
    </div>
    <div class="links">
      <a id="docLink" href="https://jdoodle.com/docs" target="_blank">Documentation</a>
      <div class="divider"></div> 
      <a id="discoverLink" href="https://jdoodle.com/docs/jdoodle-ai/introduction-to-jdroid-ai" target="_blank">Discover Jdroid</a>
    </div>
    <div class="counter-container">
      <button class="counter-button" onclick="incrementCounter()">Click me!</button>
      <div class="counter-display" id="counter">0</div>
    </div>
  </div>
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js"></script>
  <script>   
    let count = 0;
    function incrementCounter() {
      count += 1;
      document.getElementById('counter').innerText = 'Count ' + count;
    }
    document.addEventListener("DOMContentLoaded", function() {
      const imgElement = document.getElementById("logo");
      if (imgElement) {
       imgElement.src = '${window.location.origin}/assets/images/JDoodleLogo.png';
      }
    });
  </script>
</body>
</html>`,
    sampleScriptAdvance: `<html>
<head>
</head>
  <body>
   <div class="welcome">
    <div>
      <img id="logo"  style="height: 200px; width:500px" />
    </div>  
    <div>
      <h1>Your friendly cloud-based coding platform.</h1>
    </div>
    <div class="links">
      <a id="docLink" href="https://jdoodle.com/docs" target="_blank">Documentation</a>
      <div class="divider"></div> 
      <a id="discoverLink" href="https://jdoodle.com/docs/jdoodle-ai/introduction-to-jdroid-ai" target="_blank">Discover Jdroid</a>
    </div>
    <div class="counter-container">
      <button class="counter-button" onclick="incrementCounter()">Click me!</button>
      <div class="counter-display" id="counter">0</div>
    </div>
  </div>
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js"></script>
    <script src="js/script.js"></script>
    <link rel="stylesheet" href="css/style.css"/>
  </body>
</html>`
  },
  mysql: {
    metaTags: [
      {
        name: 'description',
        content:
          'Access MySQL online editor and terminal on JDoodle. Write, execute, and manage MySQL queries in a user-friendly, efficient environment.'
      },
      {
        name: 'keywords',
        content: 'Online MySQL Terminal - practice MySQL online'
      }
    ],
    title: 'MySQL Online Editor',
    header: 'MySQL',
    terminal: 'mysql',
    versions: ['5.7'],
    langDisplayName: 'MySQL',
    canonicalPath: 'online-mysql-terminal',
    sampleScript: ''
  },
  mongo: {
    metaTags: [
      {
        name: 'description',
        content:
          "Access and manage your databases with JDoodle's online MongoDB terminal and editor. Execute MongoDB queries efficiently in a user-friendly environment."
      },
      {
        name: 'keywords',
        content: 'Online mongoDB Terminal - practice mongoDB online'
      }
    ],
    title: 'Online Mongodb Terminal',
    header: 'mongoDB',
    terminal: 'mongo',
    versions: ['5.0'],
    langDisplayName: 'mongoDB',
    canonicalPath: 'online-mongodb-terminal',
    sampleScript: ''
  }
}

export const META: { [key: string]: IMeta } = {
  default: {
    metaTags: [
      {
        name: 'description',
        content:
          'JDoodle is an AI powered cloud-based online coding platform to learn, teach and compile in 88+ programming languages like Java, Python, PHP, C, C++'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'JDoodle - Free AI powered Online Coding Platform'
  },
  home: {
    metaTags: [
      {
        name: 'description',
        content:
          'JDoodle is an AI powered cloud-based online coding platform to learn, teach and compile in 88+ programming languages like Java, Python, PHP, C, C++'
      },
      {
        name: 'keywords',
        content:
          'Code online online compiler -  online code editor -  cloud IDE -  online IDE -  online programming -  Compiler API -  Compiler Plugin -  Teaching platform -  Assessment platform -  software development -  developer -  artificial intelligence -  best code editor'
      }
    ],
    title: 'JDoodle - Free AI powered Online Coding Platform'
  },
  code: {
    metaTags: [
      {
        name: 'description',
        content: `Code online for FREE in ${getLanguageCount()} languages with JDoodle's simple online compilers, editors and IDEs with cloud IDE`
      },
      {
        name: 'keywords',
        content:
          'Code online -  online compiler -  online code editor -  cloud ide -  online IDE -  online programming -  coding -  software development -  developer -  artificial intelligence -  best code editor'
      }
    ],
    title:
      'JDoodle - Online Compiler and Editor/IDE for Java, C, C++, PHP, Python, Ruby, Perl - Code and Run Online'
  },
  integrate: {
    metaTags: [
      {
        name: 'description',
        content:
          'online compiler API and IDE Plugins to compile and execute programs in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Integrate IDE -  LMS integration -  IDE APIs -  IDE Plugins -  compiler -  API -  online programming -  online ide integration'
      }
    ],
    title: 'JDoodle - Integrate online compiler plugin and API'
  },
  teachassess: {
    metaTags: [
      {
        name: 'description',
        content:
          'Create an online classroom to manage your students, create courses, exercises, assessments and track their progress. Teach programming online with JDoodle.'
      },
      {
        name: 'keywords',
        content:
          'Education platform -  auto grading -  student assignments -  virtual institute -  programming assessments -  programming courses -  online compiler -  online ide -  LMS integration'
      }
    ],
    title: 'Virtual Classroom for Teaching & Assessing Code'
  },
  educators: {
    metaTags: [
      {
        name: 'description',
        content:
          "Build your own digital classroom by creating assessments for students and automate grading. Teach coding online with JDoodle's assessment platform."
      },
      {
        name: 'keywords',
        content:
          'Education platform -  auto grading -  student assignments -  virtual institute -  programming assessments -  programming courses -  online compiler -  online ide -  online programming -  online classroom -  LMS integration'
      }
    ],
    title: 'Your Virtual Classroom for Online Assessments & Auto-Grading'
  },
  edutech: {
    metaTags: [
      {
        name: 'description',
        content:
          "Easily integrate code execution into your platform with JDoodle's compiler APIs & embeds. Create an intuitive experience for coders."
      },
      {
        name: 'keywords',
        content:
          'Education technology -  modern learning -  tech solutions -  Integrate compiler IDE -  LMS integration -  compiler IDE APIs -  IDE Plugins -  online compiler -  online IDE'
      }
    ],
    title: 'Integrate IDE on your platform | JDoodle APIs & Embeds'
  },
  libprovider: {
    metaTags: [
      {
        name: 'description',
        content: `Integrate JDoodle's Compiler APIs and Plugins seamlessly to showcase your software library effectively. Offer interactive documentation, secure code execution, and support for ${getLanguageCount()} languages.`
      },
      {
        name: 'keywords',
        content:
          'Software libraries -  compiler API integration -  compiler IDE Plugins -  online compiler -  online ide'
      }
    ],
    title: 'JDoodle Compiler Plugins: Enhance Product Demonstrations'
  },
  aboutus: {
    metaTags: [
      {
        name: 'description',
        content:
          'An AI powered online programming platform that helps millions of students, teachers and developers with tools to learn, teach and practice programming.'
      },
      {
        name: 'keywords',
        content: 'JDoodle mission -  about us -  company story -  team'
      }
    ],
    title: 'About Us - JDoodle'
  },
  contactus: {
    metaTags: [
      {
        name: 'description',
        content:
          'Contact our support team at hello@jdoodle.com or fill out our contact form for more personalized assistance.'
      },
      {
        name: 'keywords',
        content: 'Contact JDoodle -  support inquiries -  get in touch'
      }
    ],
    title: 'Contact Us - JDoodle'
  },
  pricing: {
    metaTags: [
      {
        name: 'description',
        content: "Explore JDoodle's pricing plans."
      },
      {
        name: 'keywords',
        content:
          "Explore JDoodle's pricing options for individuals & teams. Includes pricing for AI powered IDE, Embeds and Assessment Platform."
      }
    ],
    title: 'JDoodle Pricing'
  },
  blogs: {
    metaTags: [
      {
        name: 'description',
        content:
          "Explore insightful coding, technology, and education blogs on JDoodle's AI powered online coding platform."
      },
      {
        name: 'keywords',
        content: 'Coding blogs -  technology insights -  education blogs'
      }
    ],
    title: 'JDoodle Blog | Latest Updates, Industry News & more'
  },
  api: {
    metaTags: [
      {
        name: 'description',
        content:
          'Compiler API is an online API to compile and execute programs in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online - Online Program Runner -  Online Compiler API'
      }
    ],
    title: 'Online Compiler API for Java, C, C++, PHP, Python, Ruby, Perl'
  },
  guru: {
    metaTags: [
      {
        name: 'description',
        content:
          'Guru - Online Assessment, Assignment, Tests and Courses in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Guru - Online Assessment, Assignment Tests and Courses in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      }
    ],
    title: 'Guru - Online Assessment, Assignments, Tests and Courses'
  },
  tutorials: {
    metaTags: [
      {
        name: 'description',
        content:
          'Integrate our online IDE through Compiler APIs and Embeds to execute code within your application, LMS, and course materials.'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'JDoodle Tutorials'
  },
  webinar: {
    metaTags: [
      {
        name: 'description',
        content:
          'Learn how you can make the best use of JDoodle’s online coding platform for practising, teaching & building code in 88+ programming languages. Register Now!'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'JDoodle Webinars'
  },
  docs: {
    metaTags: [
      {
        name: 'description',
        content:
          'Find easy-to-follow articles and tutorials to get started with JDoodle, manage your account, and unlock the full potential of its development tools and features.'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'JDoodle Documentation'
  },
  chatJdroid: {
    metaTags: [
      {
        name: 'description',
        content:
          "JDroid, JDoodle's AI assistant, can help generate new code, get explanations, debug errors, comment your code, and optimize for efficiency.  Write better code faster with JDroid in JDoodle's Online IDE."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "Chat JDroid - JDoodle's AI Coding Tool"
  },
  caseStudyKansas: {
    metaTags: [
      {
        name: 'description',
        content:
          "Learn how University of Kansas ATS used JDoodle's Compiler API to streamline code compilation in their assessment tools, enhancing the learning experience for students and educators."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "University of Kansas ATS Enhances Assessments with JDoodle's Compiler API"
  },
  caseStudyItext: {
    metaTags: [
      {
        name: 'description',
        content:
          "Discover how iText leverages JDoodle's Compiler API to effortlessly showcase their PDF library. Enjoy instant accessibility, streamlined demos, and project-free testing without setup or trial forms."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "iText Enhances PDF Library Showcases with JDoodle's Compiler API"
  },
  caseStudyKodezi: {
    metaTags: [
      {
        name: 'description',
        content:
          "Learn how Kodezi improved their coding platform with JDoodle's Compiler API, offering a user-friendly environment, simplified code execution, real-time feedback, and reliable performance."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "Kodezi's Enhanced Coding with JDoodle's Compiler API"
  },
  caseStudyLinkgroup: {
    metaTags: [
      {
        name: 'description',
        content:
          "See how LINK Group enhanced LMS interactivity with JDoodle's compiler plugins, letting students run code directly within the platform for a seamless learning experience."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "LINK Group Enhances LMS with JDoodle's Interactive Compiler Integration"
  },
  caseStudyOutlier: {
    metaTags: [
      {
        name: 'description',
        content:
          "Discover how Outlier.org improved coding education for beginners with JDoodle's Java compiler. Enjoy enhanced accessibility, real-time feedback, and a user-friendly interface."
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: "Outlier.org Enhances Coding Education with JDoodle's Java Compiler"
  },
  profile: {
    metaTags: [
      {
        name: 'description',
        content: 'Create and manage your JDoodle profile.'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'JDoodle - Profile'
  },
  ...LANGUAGEMETA
}

/**
 * @param to - redirecting to the actual route
 * @returns - returns the modified path with adding /
 */
export const setDefaultPath = (to: any) => {
  const hasTrailingSlash = to.path.endsWith('/')

  if (hasTrailingSlash && to.path.length > 1) {
    return { ...to, path: to.path.slice(0, -1) }
  } else return
}
