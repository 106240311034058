<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch, computed, onBeforeUnmount } from 'vue'
import { useSSGStore } from '@/stores/ssg.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import supbaseService from '@/services/supbase.service'
import { useRoute, useRouter } from 'vue-router'
import {
  AICHAT_TYPE,
  AICONSTANT,
  AIType,
  ChatStatus,
  type IChat,
  AICHAT_MODE
} from '@/utils/jDroid'
import ChatComp from '@/components/ide/ide/ai/jdroid/ChatComp.vue'
import chatJdroidSidebar from '@/assets/images/svg/Chatjdroid_sidebar.svg'
import chatJdroidComment from '@/assets/images/svg/Chatjdroid_comment.svg'
import chatJdroidService, {
  type IChatHistory,
  type IChatQuestion
} from '@/services/chatjdroid.service'
import jdroidLogo from '@/assets/images/ide/ai/jdroid-icon.svg'
import { useAuthStore } from '@/stores/auth.store'
import { IDERELETIVEHEIGHT } from '@/utils/ide'
import Recaptcha from '@/components/shared/RecaptchaComp.vue'

interface AIChatItem {
  chatName: string
  id: string
  projectKey?: null | string
  sessionId?: number
  type?: string
  version?: number
}

interface IJDroidChat {
  id: string // uuid
  version: number
  type: string
  lastUpdated: string
  chatName: string
  sessionid: string
  projectKey: number
}

const route = useRoute()
const router = useRouter()
const ssgStore = useSSGStore()
const authStore = useAuthStore()
const jdroidStore = useJdroidStore()

const isLoggedIn = computed(() => {
  return authStore.isInitiated && authStore.isUserloggedIn
})

const isOpen = ref<boolean>(true)

const chatHistory = computed(() => {
  return jdroidStore.chatList
})

const chatList = ref<AIChatItem[]>([])
const currentChatId = ref<string | null>(null)
const deletedChatId = ref<string | null>(null)
const isMobileView = ref<boolean>(false)

/**
 * Toggles the sidebar
 */
const toggleSidebar = () => {
  isOpen.value = !isOpen.value
}

/**
 * Get AI chat
 */
const getAIChat = () => {
  chatJdroidService.getChatsByAccountApi().then((data) => {
    chatList.value = data
  })
}

/**
 * Generate chat title
 * @param chatId string
 * @param chat chat
 */
const generateChatTitle = (chatId: string, chat: IChat) => {
  chatJdroidService.generateTitleWithAI(chatId, chat).then((data) => {
    chatList.value?.unshift({
      id: chatId,
      chatName: data.trim()
    })
    generateChatUrl(chatId, data)
  })
}

/**
 * Different flows for logged/unlogged states
 * @param chat chat
 */
const startChatOnLoginState = (chat: IChat) => {
  if (!isLoggedIn.value) {
    chatJdroidService.generateAnswerForUnlogged(chat)
  } else {
    startAIChat(chat)
  }
}

/**
 * Start Chat with AI
 * @param chat chat
 */
const startAIChat = async (chat: IChat) => {
  if (!currentChatId.value) {
    const requestObj = {
      chatName: chat.header,
      projectKey: null,
      type: AICHAT_TYPE.DEFAULT
    }

    if (route.params.initMode && route.params.initMode == AICHAT_MODE.QA) {
      // Create new chat with jdroid answers
      createNewChatByAnswer(chat)
    } else {
      // Create new chat based users' inputs
      chatJdroidService.createChatApi(requestObj).then(async (data: IJDroidChat) => {
        currentChatId.value = data.id
        generateChatTitle(data.id, chat)
        chatJdroidService.addChat(data.id, chat)
      })
    }
  } else {
    chatJdroidService.addChat(currentChatId.value, chat)
  }
}

/**
 *
 * @param newChat Chat
 */
const createNewChatByAnswer = async (newChat: IChat) => {
  // First create a new chat
  const answer = (
    await supbaseService.getQuestionAndAnswer(parseInt(route?.params?.questionId as string))
  ).data[0]

  const answerChat: IChat = {
    type: AIType.CHATJDROID,
    autoReplaceEditor: false,
    ideContent: null,
    outputContent: null,
    prompt: answer?.body,
    header: answer?.title || 'JDroid chat',
    status: ChatStatus.SUCCESS,
    response: answer?.jdroid
  }

  const requestObj = {
    chatName: answerChat.header,
    projectKey: null,
    type: AICHAT_TYPE.DEFAULT
  }

  if (!answer) {
    router.replace('/chat-jdroid')
    return
  }

  chatJdroidService.createChatApi(requestObj).then(async (data: IJDroidChat) => {
    currentChatId.value = data.id

    chatList.value?.unshift({
      id: data.id,
      chatName: answer.title
    })

    generateChatUrl(data.id, answer.title)

    useJdroidStore().setShowJdroid(true)

    // Then add the current question/answer to the chat
    const newQuestion = {
      history: [
        {
          aiPayload: JSON.stringify({
            inputs: answer.body
          }),
          answer: JSON.stringify(answer.jdroid)
        }
      ],
      chatId: data.id
    } as IChatHistory

    chatJdroidService.addQuestionToChat(newQuestion).then(() => {
      // Finally add the new question the chat
      chatJdroidService.addChat(data.id, newChat)
    })
  })
}

/**
 * Delete chat
 * @param chatId chat id
 */
const deleteChat = (chatId: string) => {
  deletedChatId.value = chatId
  chatJdroidService.deleteChatByIdApi(chatId).then(() => {
    deletedChatId.value = null
    if (currentChatId.value == chatId) {
      getAIChat()
      currentChatId.value = null
      jdroidStore.clearChat()
    } else {
      chatList.value = chatList.value.filter((item) => item.id !== chatId)
    }
  })
}

/**
 * Create a new chat
 */
const createNewChat = () => {
  currentChatId.value = null
  jdroidStore.clearChat()
  router.replace('/chat-jdroid')
}

/**
 * Get chat details
 * @param chatId chat id
 */
const getChatDetail = (chatId: string) => {
  currentChatId.value = chatId
  jdroidStore.clearChat()

  chatJdroidService.getChatByIdApi(chatId).then((data) => {
    generateChatUrl(chatId, data.chat.chatName)

    // Close sidebar for mobile layout
    if (isMobileView.value) {
      isOpen.value = false
    }

    data.questions.forEach((question: any) => {
      const type = JSON.parse(question.questionPayload).type
      const inputs = JSON.parse(question.questionPayload).inputs

      const chat: IChat = {
        type: type,
        prompt: inputs,
        status: ChatStatus.SUCCESS,
        response: question.answer
      }

      jdroidStore.addChat(chat)
    })
  })
}

/**
 * Get the height of the header navbar
 */
const updateHeaderHeight = () => {
  const header = document.getElementById(IDERELETIVEHEIGHT.EXCLUDEID)
  const height = header?.offsetHeight
  const chatJdoridEl = document.getElementById(AICONSTANT.CHATJDROID)

  if (height && chatJdoridEl) {
    if (isMobileView.value) {
      chatJdoridEl.style.height = `calc(100dvh - ${height + 40 + 48}px)` // 40px: height of the simplified footer; 48px: topbar for mobile layout
    } else {
      chatJdoridEl.style.height = `calc(100dvh - ${height + 40}px)` // 40px: height of the simplified footer
    }
  }
}

/**
 * Remove punctuations in url
 * @param url uri string
 * @returns cleaned url
 */
const cleanedUrl = (url: string) => {
  return encodeURIComponent(url.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''))
}

/**
 * Generate url for a chat
 * @param chatId chat id
 * @param title chat title
 */
const generateChatUrl = (chatId: string, title: string) => {
  router.replace(`/chat-jdroid/${AICHAT_MODE.CHAT}/${chatId}/${cleanedUrl(title)}`)
}

/**
 * Store the chats happened before login
 */
const storeChatsBeforeLogin = () => {
  const requestObj = {
    chatName: chatHistory.value[0].header,
    projectKey: null,
    type: AICHAT_TYPE.DEFAULT
  }

  // Create new chat based users' inputs
  chatJdroidService.createChatApi(requestObj).then(async (data: IJDroidChat) => {
    currentChatId.value = data.id
    generateChatTitle(data.id, chatHistory.value[0])

    // Add chat history to the new chat
    const history: IChatQuestion[] = []
    chatHistory.value.forEach((element) => {
      if (element.response) {
        history.push({
          aiPayload: JSON.stringify({
            inputs: element.prompt
          }),
          answer: (element.response as string) || ''
        })
      }
    })

    const newQuestions = {
      history: history,
      chatId: data.id
    } as IChatHistory

    chatJdroidService.addQuestionToChat(newQuestions).then(() => {
      // Finally add the new question the chat
      // chatJdroidService.addChat(data.id, newChat)
    })
  })
}

onBeforeMount(() => {
  jdroidStore.setIsJdroidChatView(true)

  if (window.innerWidth < 640) {
    isMobileView.value = true
    isOpen.value = false
  } else {
    isMobileView.value = false
  }
})

if (import.meta.env.SSR) {
  const SSGJdriodQuestion = jdroidStore.ssgJdroidAllQuestion

  const answer = SSGJdriodQuestion.filter(
    (item) => (item.so_id as any) == parseInt(route?.params?.questionId as string)
  )
  const chat: IChat = {
    type: AIType.CHATJDROID,
    autoReplaceEditor: false,
    ideContent: null,
    outputContent: null,
    prompt: answer[0]?.body,
    header: 'Chat with JDroid',
    status: ChatStatus.SUCCESS,
    response: answer[0]?.jdroid as string
  }
  useJdroidStore().setShowJdroid(true)
  useJdroidStore().addChat(chat)
}
onMounted(async () => {
  // JDroid answer
  watch(
    () => authStore.isInitiated,
    async (isAppInitiated) => {
      if (isAppInitiated && route.params.initMode && route.params.initMode == AICHAT_MODE.QA) {
        jdroidStore.clearChat()

        const answer = (
          await supbaseService.getQuestionAndAnswer(parseInt(route?.params?.questionId as string))
        ).data

        const chat: IChat = {
          type: AIType.CHATJDROID,
          autoReplaceEditor: false,
          ideContent: null,
          outputContent: null,
          prompt: answer[0]?.body,
          header: 'Chat with JDroid',
          status: ChatStatus.SUCCESS,
          response: answer[0]?.jdroid
        }

        useJdroidStore().setShowJdroid(true)
        useJdroidStore().addChat(chat)
      }

      if (
        isAppInitiated &&
        route.params.initMode &&
        route.params.initMode == AICHAT_MODE.CHAT &&
        route.params.questionId &&
        isLoggedIn.value
      ) {
        getChatDetail(route.params.questionId as string)
      }
    },
    { immediate: true }
  )

  // Get chat list
  watch(
    isLoggedIn,
    async (value) => {
      if (value) {
        getAIChat()
      }

      if (route.fullPath == '/chat-jdroid' && chatHistory.value && chatHistory.value.length > 0) {
        storeChatsBeforeLogin()
      }
    },
    { immediate: true }
  )

  // Set the height of the chat jdroid element
  const chatJdoridEl = document.getElementById(AICONSTANT.CHATJDROID)
  if (chatJdoridEl) {
    chatJdoridEl.style.height = isMobileView.value
      ? `calc(100dvh - ${47 + 40 + 120}px)` // 40px: height of the simplified footer; 47px: height of header; 48px: height of topbar in chat jdroid
      : `calc(100dvh - ${72 + 28}px)` // 28px: height of the simplified footer; 47px: height of header;
  }
})

onBeforeUnmount(() => {
  jdroidStore.setIsJdroidChatView(false)
})

if (!ssgStore.isSSG) {
  window.addEventListener('resize', () => {
    updateHeaderHeight()

    if (window.innerWidth < 640) {
      isMobileView.value = true
    } else {
      isMobileView.value = false
    }
  })
}
</script>

<template>
  <Recaptcha />
  <!-- Mobile topbar -->
  <div
    class="mx-4 flex h-12 flex-row items-center justify-between border-b border-[#9EB1D5]/50 sm:hidden"
  >
    <img
      :src="chatJdroidSidebar"
      class="h-6 w-6 cursor-pointer"
      @click="toggleSidebar"
      title="Open sidebar"
    />
    <img :src="jdroidLogo" class="h-8 w-8" />
    <div class="flex cursor-pointer flex-row gap-1.5 text-neutral-600" @click="createNewChat">
      <img :src="chatJdroidComment" class="h-6 w-6" title="New chat" />New Chat
    </div>
  </div>

  <div :id="AICONSTANT.CHATJDROID" class="flex flex-row overflow-hidden">
    <div
      :class="[
        'shrink-0 grow-0  transition-[width] duration-300',
        isOpen ? 'w-2/3 sm:w-64' : 'w-0 sm:w-16',
        'border-r border-gray-300 bg-[#EBEFF6]',
        isMobileView && isOpen ? 'absolute bottom-0 left-0 top-[46px] z-40' : 'relative h-full'
      ]"
    >
      <div
        class="w-full items-center justify-between p-2"
        :class="isOpen ? 'grid grid-cols-2' : 'flex flex-col gap-4'"
      >
        <img :src="jdroidLogo" class="h-8 w-8" />

        <div
          class="flex w-full justify-end"
          :class="isOpen ? 'flex-row gap-3' : 'flex-col items-center gap-4'"
        >
          <img
            :src="chatJdroidSidebar"
            class="h-6 w-6 cursor-pointer"
            @click="toggleSidebar"
            title="Open sidebar"
          />
          <img
            :src="chatJdroidComment"
            class="h-6 w-6 cursor-pointer"
            title="New chat"
            @click="createNewChat"
          />
        </div>
      </div>

      <div v-if="isOpen">
        <!-- Add your sidebar content here -->
        <div
          class="section-quaternary my-2 mt-9 h-[70dvh] cursor-pointer overflow-y-auto rounded bg-[#EBEFF6] text-sm text-neutral-700 transition-colors duration-300"
        >
          <ul>
            <li
              v-for="chat in chatList"
              :key="chat.id"
              class="flex h-10 items-center justify-between px-3 py-1 hover:bg-[#DADEE5]"
              :class="{ 'bg-[#DADEE5]': currentChatId == chat.id }"
            >
              <p
                class="w-full overflow-hidden text-ellipsis whitespace-pre py-2"
                :class="{ 'animate-pulse': deletedChatId == chat.id }"
                :title="chat.chatName"
                @click="getChatDetail(chat.id)"
              >
                {{ chat.chatName }}
              </p>
              <div class="hs-tooltip inline-block [--placement:right] [--trigger:click]">
                <button
                  type="button"
                  class="hs-tooltip-toggle flex items-center justify-center p-2 hover:text-gray-500 disabled:pointer-events-none disabled:opacity-50"
                >
                  <FontAwesomeIcon icon="fa-ellipsis-vertical"></FontAwesomeIcon>

                  <div
                    class="hs-tooltip-content invisible absolute z-10 inline-block overflow-hidden rounded-lg border border-slate-300 bg-[#EBEFF6] text-sm text-gray-600 shadow-md transition-opacity hover:bg-red-500 hs-tooltip-shown:visible hs-tooltip-shown:opacity-100"
                    role="tooltip"
                    @click="deleteChat(chat.id)"
                  >
                    <div class="w-full px-5 py-3 hover:bg-gray-300">
                      <FontAwesomeIcon icon="fa-trash" class="mr-1"></FontAwesomeIcon> Delete
                    </div>
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Mask layer for mobile view -->
    <div
      v-show="isMobileView && isOpen"
      class="absolute left-0 right-0 top-0 z-30 h-full w-full bg-black/40"
      @click="toggleSidebar"
    ></div>

    <div
      class="fade-in mx-auto grow overflow-hidden p-4 md:max-w-3xl md:pt-[2dvh] lg:max-w-[40rem] lg:px-0 xl:max-w-[48rem]"
    >
      <ChatComp :isChatJdroid="true" :chatId="currentChatId" @startChat="startChatOnLoginState" />
    </div>
  </div>

  <!-- <FooterComp /> -->
</template>
