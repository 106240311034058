import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUtilModelsStore = defineStore('utilModels', () => {
  const confirmPromise = ref<Promise<boolean> | null>(null)
  const confirmPromiseFinish = ref<boolean>(true)
  const confirmPromiseResolve = ref<((value: boolean) => void) | null>(null)
  const confirmPromiseReject = ref<((reason?: any) => void) | null>(null)

  const confrimTitle = ref<string | null>(null)
  const confrimSubtitle = ref<string | null>(null)
  const confrimReturnHs = ref<string | null>(null)
  const confrimYesBtnText = ref<string | null>(null)
  const confrimNoBtnText = ref<string | null>(null)

  const alertTimeOut = ref<NodeJS.Timeout | number | string | null>(null)
  const alertTitle = ref<string | null>(null)
  const alertSubtitle = ref<string | null>(null)
  const alertReturnHs = ref<string | null>(null)

  const showInfo = ref<boolean>(false)
  const infoTitle = ref<string | null>(null)
  const infoSubtitle = ref<string | null>(null)

  const isConfirmPromise = computed(() => {
    return confirmPromise.value || null
  })
  const isConfirmPromiseFinish = computed(() => {
    return confirmPromiseFinish.value || false
  })
  const isConfirmTitle = computed(() => {
    return confrimTitle.value || null
  })
  const isConfirmSubtitle = computed(() => {
    return confrimSubtitle.value || null
  })
  const isConfirmReturnHs = computed(() => {
    return confrimReturnHs.value || null
  })
  const isConfirmYesBtnText = computed(() => {
    return confrimYesBtnText.value || null
  })
  const isConfirmNoBtnText = computed(() => {
    return confrimNoBtnText.value || null
  })
  const isAlertTitle = computed(() => {
    return alertTitle.value || null
  })
  const isAlertSubtitle = computed(() => {
    return alertSubtitle.value || null
  })
  const isAlertReturnHs = computed(() => {
    return alertReturnHs.value || null
  })
  const isInfoTitle = computed(() => {
    return infoTitle.value || null
  })
  const isInfoSubtitle = computed(() => {
    return infoSubtitle.value || null
  })
  /**
   * Create a promise to confirm the action
   * @param title title of the confirm dialog
   * @param subtitle subtitle of the confirm dialog
   * @param retunHs return hash to return to after confirm
   * @param yesBtnText yes button text
   * @param noBtnText  no button text
   * @returns promise to confirm the action
   */
  const setconfrimDetails = (
    title: string,
    subtitle: string | null,
    retunHs: string | null,
    yesBtnText: string,
    noBtnText: string
  ): Promise<boolean> => {
    confrimTitle.value = title
    confrimSubtitle.value = subtitle
    confrimReturnHs.value = retunHs
    confrimYesBtnText.value = yesBtnText
    confrimNoBtnText.value = noBtnText

    confirmPromise.value = new Promise((resolve, reject) => {
      confirmPromiseFinish.value = false
      confirmPromiseResolve.value = resolve
      confirmPromiseReject.value = reject
    })

    return confirmPromise.value
  }
  /**
   * on confirm click
   */
  const setconfrimDetailsResolve = () => {
    if (confirmPromiseResolve.value) {
      confirmPromiseFinish.value = true
      confirmPromiseResolve.value(true)
    }
  }
  /**
   * on reject click
   */
  const setconfrimDetailsReject = () => {
    if (confirmPromiseReject.value) {
      confirmPromiseFinish.value = true
      confirmPromiseReject.value(true)
    }
  }
  /**
   * Create a time out alert
   * @param title title of the alert dialog
   * @param subtitle subtitle of the alert dialog
   * @param retunHs return hash to return to after alert
   */
  const setAlertDetails = (title: string, subtitle: string | null, retunHs: string | null) => {
    alertTitle.value = title
    alertSubtitle.value = subtitle
    alertReturnHs.value = retunHs
    alertTimeOut.value = 'new'
  }
  /**
   * set info dialog details
   * @param show show the alert dialog
   * @param title title of the alert dialog
   * @param subtitle subtitle of the alert dialog
   */
  const setInfoDetails = (show: boolean, title: string, subtitle: string | null) => {
    showInfo.value = show
    infoTitle.value = title
    infoSubtitle.value = subtitle
  }
  return {
    isConfirmPromise,
    isConfirmPromiseFinish,
    isConfirmTitle,
    isConfirmSubtitle,
    isConfirmReturnHs,
    isConfirmYesBtnText,
    isConfirmNoBtnText,
    setconfrimDetails,
    setconfrimDetailsResolve,
    setconfrimDetailsReject,
    alertTimeOut,
    isAlertTitle,
    isAlertSubtitle,
    isAlertReturnHs,
    setAlertDetails,
    showInfo,
    isInfoTitle,
    isInfoSubtitle,
    setInfoDetails
  }
})
