declare global {
  interface Window {
    HSTabs: any
    $hsTabsCollection: any
  }
}
/**
 * open the tab
 * @param hs hash to close
 * @param scrollIntoView scroll to the tab
 */
const setActiveTab = (hs: string, scrollIntoView: boolean = true) => {
  const current: HTMLElement | null = document.querySelector(`#${hs}-item`)
  if (!current) return

  const tab: HTMLElement | null = current.closest('[aria-label="Tabs"][role="tablist"]')

  if (!tab) return

  const elInCollection = window?.$hsTabsCollection?.find((el: any) =>
    Array.from(el.element.toggles || []).includes(current)
  )

  if (!elInCollection && window && window.HSTabs) {
    current.classList.add('active')
    const element = new window.HSTabs(tab)
    window?.$hsTabsCollection?.push({
      id: element?.el?.id || window?.$hsTabsCollection?.length + 1,
      element
    })
    element.open(current)
  } else {
    elInCollection.element.open(current)
  }
  if (scrollIntoView) current.scrollIntoView({ behavior: 'smooth' })
}
/**
 * scroll to output editor on execute program
 * @param hs id to scroll
 */
const scrollToOutputEditor = (hs: string) => {
  const htmlHs: HTMLElement | null = document.getElementById(hs)
  if (htmlHs) {
    htmlHs.scrollIntoView({ behavior: 'smooth' })
  }
}

export default {
  setActiveTab,
  scrollToOutputEditor
}
